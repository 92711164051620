import { ApiConstants } from 'src/app/business/api/api.constants';
import { TourScheduleType } from './tour-schedule-type.constants';
import { SchedulePlanningDay } from '../schedule-planning-day.model';
import { Language } from '../../master/language/language.model';
import { TourState } from './tour-state.constants';
import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { TourResume } from '../tour-resume.model';
import { TourType } from '../../master/tour/type/type.model';
import { Country } from '../../master/country/country.model';
import { City } from '../../master/city/city.model';
import { Fare } from '../fare.model';
import { ImageUtils } from '../../image/common/image.utils';
import { TourResumeAdmin } from '../tour-resume-admin.model';
import { Tour } from '../tour.model';
import { Attraction } from '../attraction.model';
import { SchedulePlanning } from '../schedule-planning.model';
import { ScheduleSeason } from '../schedule-season.model';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { Schedule } from '../schedule.module';
import { TourResumeAffiliate } from '../tour-resume-affiliate.model';
import { MathUtils } from 'src/app/domain/utils/math-utils';
import { TourAffiliateLink } from '../tour-affiliate-link.model';

export class TourUtils {

    public static getScheduleTypeFromApi(type: number): TourScheduleType {

        if ( type == ApiConstants.API_TOUR_SCHEDULE_TYPE_FIXED ) {
            return TourScheduleType.fixed;
        } else {
            return TourScheduleType.seasons;
        }
    }


    public static getApiTypeFromTourScheduleType(type: TourScheduleType): number {

        if ( type == TourScheduleType.fixed ) {
            return ApiConstants.API_TOUR_SCHEDULE_TYPE_FIXED;
        } else {
            return ApiConstants.API_TOUR_SCHEDULE_TYPE_SEASON;
        }
    }


    public static getTourStateFromApi(state: number): TourState {

        if ( state == ApiConstants.API_TOUR_STATE_ACTIVE ) {
            return TourState.Active;
        } else {
            return TourState.Inactive;
        }
    }



    public static getTourScheduleWeekdayFromApi(weekday: number): TourScheduleWeekdaysType {

          switch ( weekday ) {
              case ApiConstants.API_SCHEDULE_WEEKDAY_MO:
                  return TourScheduleWeekdaysType.Monday;
              case ApiConstants.API_SCHEDULE_WEEKDAY_TU:
                  return TourScheduleWeekdaysType.Tuesday;
              case ApiConstants.API_SCHEDULE_WEEKDAY_WE:
                  return TourScheduleWeekdaysType.Wednesday;
              case ApiConstants.API_SCHEDULE_WEEKDAY_TH:
                  return TourScheduleWeekdaysType.Thursday;
              case ApiConstants.API_SCHEDULE_WEEKDAY_FR:
                  return TourScheduleWeekdaysType.Friday;
              case ApiConstants.API_SCHEDULE_WEEKDAY_SA:
                  return TourScheduleWeekdaysType.Saturday;
              case ApiConstants.API_SCHEDULE_WEEKDAY_SU:
                  return TourScheduleWeekdaysType.Sunday;
              default:
                  return null;
          }
    }



    public static getApiWeekdayFromTourScheduleWeekday(weekday: TourScheduleWeekdaysType): number {

          switch ( weekday ) {
              case TourScheduleWeekdaysType.Monday:
                  return ApiConstants.API_SCHEDULE_WEEKDAY_MO;
              case TourScheduleWeekdaysType.Tuesday:
                  return ApiConstants.API_SCHEDULE_WEEKDAY_TU;
              case TourScheduleWeekdaysType.Wednesday:
                  return ApiConstants.API_SCHEDULE_WEEKDAY_WE;
              case TourScheduleWeekdaysType.Thursday:
                  return ApiConstants.API_SCHEDULE_WEEKDAY_TH;
              case TourScheduleWeekdaysType.Friday:
                  return ApiConstants.API_SCHEDULE_WEEKDAY_FR;
              case TourScheduleWeekdaysType.Saturday:
                  return ApiConstants.API_SCHEDULE_WEEKDAY_SA;
              case TourScheduleWeekdaysType.Sunday:
                  return ApiConstants.API_SCHEDULE_WEEKDAY_SU;
              default:
                  return null;
          }
    }



    public static getSchedulePlanningDaysFromApiDays(days: any): Array<SchedulePlanningDay> {
        const result: Array<SchedulePlanningDay> = new Array<SchedulePlanningDay>();

        if ( days ) {
            days.map( (day: any) => {
              const hours: Array<string> = new Array<string>();
              day.hours.map( (hour: any) => {
                  hours.push(hour);
              });

              const weekday :TourScheduleWeekdaysType = this.getTourScheduleWeekdayFromApi( day.day );
              result.push( new SchedulePlanningDay( weekday, hours ) );
            })
        }

        return result;

    }



    public static getSchedulePlanningExceptionDaysFromApiExceptionDays(days: any): Array<SchedulePlanningDay> {
        const result: Array<SchedulePlanningDay> = new Array<SchedulePlanningDay>();

        if ( days ) {
            days.map( (day: any) => {
              const hours: Array<string> = new Array<string>();
              day.hours.map( (hour: any) => {
                  hours.push(hour);
              });

              result.push( new SchedulePlanningDay( CalendarUtils.fromFormattedDateToDDMMYYYY(day.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY), hours ) );

            })
        }

        return result;

    }





    public static getFareNameByLanguageId(fareId: number, languageId: number, responseI18N: Array<any>): string {
        let result = null;

        if ( languageId ) {
            responseI18N.map( (i18n: any) => {
                if ( i18n.language.id == languageId && i18n.translation ) {
                    i18n.translation.fares.map( (fareI18N: any) => {
                        if ( fareI18N.id == fareId ) {
                            result = fareI18N.name;
                        }
                    });
                }
            });
        }

        return result;

    }



    public static getFareNameByLanguageCode(fareId: number, languageCode: string, responseI18N: Array<any>): string {
        let result = null;

        if ( languageCode ) {
            responseI18N.map( (i18n: any) => {
                if ( i18n.language.code == languageCode && i18n.translation ) {
                    i18n.translation.fares.map( (fareI18N: any) => {
                        if ( fareI18N.id == fareId ) {
                            result = fareI18N.name;
                        }
                    });
                }
            });
        }

        return result;

    }



    public static getAttractionNameByLanguageId(attractionId: number, languageId: number, responseI18N: Array<any>): string {
        let result = null;

        if ( languageId ) {
            responseI18N.map( (i18n: any) => {
                if ( i18n.language.id == languageId && i18n.translation) {
                    i18n.translation.attractions.map( (attractionI18n: any) => {
                        if ( attractionI18n.id == attractionId ) {
                            result = attractionI18n.name;
                        }
                    });
                }
            });
        }

        return result;

    }


    public static getkeyI18NByLanguageId(apiKeyName: string, languageId: number, responseI18N: Array<any>): string {
        let result = null;

        if ( languageId ) {
            responseI18N.map( (i18n: any) => {
                if ( i18n.language.id == languageId && i18n.translation ) {
                    const entriesOfFilters = Object.entries(i18n.translation);
                    for (const [key, value] of entriesOfFilters) {
                        if ( key == apiKeyName )  {
                            result = value;
                        }
                    }
                }
            });
        }

        return result;

    }


    public static getkeyI18NByLanguageCode(apiKeyName: string, languageCode: string, responseI18N: Array<any>): string {
        let result = null;

        if ( languageCode ) {
            responseI18N.map( (i18n: any) => {
                if ( i18n.language.code == languageCode && i18n.translation ) {
                    const entriesOfFilters = Object.entries(i18n.translation);
                    for (const [key, value] of entriesOfFilters) {
                        if ( key == apiKeyName )  {
                            result = value;
                        }
                    }
                }
            });
        }

        return result;

    }




    public static getAffiliateLinksWithLanguageName(responseI18N: Array<any>): Array<TourAffiliateLink> {
        let affiliateLinks = new Array<TourAffiliateLink>();

       responseI18N.map( (i18n: any) => {
            if ( i18n.translation && i18n.translation.webUrl ) {
                 affiliateLinks.push(new TourAffiliateLink(i18n.translation.webUrl, i18n.language.name));
            }
       });

        return affiliateLinks;

    }


    public static getLanguageI18NByLanguageId(languageId: number, languageI18NMain: Language, responseI18N: Array<any>): Language {
        let result = new Language(languageI18NMain.id, languageI18NMain.code, languageI18NMain.name);

        if ( languageId ) {
            responseI18N.map( (i18n: any) => {
                if ( i18n.language.id == languageId ) {
                    result = new Language(i18n.language.id, i18n.language.code, i18n.language.name);
                }
            });
        }

        return result;

    }

    public static getLanguageI18NAlternatives(responseI18N: Array<any>): Array<Language> {
        let result = new Array<Language>();

          responseI18N.map( (i18n: any) => {
              result.push(new Language(i18n.language.id, i18n.language.code, i18n.language.name));
          });

        return result;

    }



    public static getTourResumeFromApi(tourBasicApi: any, localeI18N: string): TourResume {

         const languageMain: Language = new Language(tourBasicApi.languageTourMain.id, tourBasicApi.languageTourMain.code, tourBasicApi.languageTourMain.name);

         return new TourResume(
           tourBasicApi.id,
           TourUtils.getkeyI18NByLanguageCode('name', localeI18N, tourBasicApi.i18n),
           TourUtils.getkeyI18NByLanguageId('name', languageMain.id, tourBasicApi.i18n),
           TourUtils.getkeyI18NByLanguageCode('descriptionShort', localeI18N, tourBasicApi.i18n),
           TourUtils.getkeyI18NByLanguageId('descriptionShort', languageMain.id, tourBasicApi.i18n),
           new Language(tourBasicApi.languageTourGiven.id, tourBasicApi.languageTourGiven.code, tourBasicApi.languageTourGiven.name),
           new TourType(tourBasicApi.type.id, tourBasicApi.type.name),
           new Country(tourBasicApi.country.id, tourBasicApi.country.code, tourBasicApi.country.name),
           new City(tourBasicApi.city.id, tourBasicApi.city.name),
           tourBasicApi.duration,
           tourBasicApi.isFree,
           new Array<Fare>(),
           ImageUtils.getImageDetailsFromApi(tourBasicApi.firstImage),
           tourBasicApi.bid,
           tourBasicApi.bidMin,
           tourBasicApi.bidBlocked,
           tourBasicApi.customCommissionFare,
           tourBasicApi.defaultCommissionFare,
           TourUtils.getTourStateFromApi(tourBasicApi.state),
         );

    }



    public static getTourResumeAffiliateFromApi(tourBasicApi: any, localeI18N: string): TourResumeAffiliate {

         const languageMain: Language = new Language(tourBasicApi.languageTourMain.id, tourBasicApi.languageTourMain.code, tourBasicApi.languageTourMain.name);

         return new TourResumeAffiliate(
           tourBasicApi.id,
           TourUtils.getkeyI18NByLanguageCode('name', localeI18N, tourBasicApi.i18n),
           TourUtils.getkeyI18NByLanguageId('name', languageMain.id, tourBasicApi.i18n),
           TourUtils.getkeyI18NByLanguageCode('descriptionShort', localeI18N, tourBasicApi.i18n),
           TourUtils.getkeyI18NByLanguageId('descriptionShort', languageMain.id, tourBasicApi.i18n),
           new Language(tourBasicApi.languageTourGiven.id, tourBasicApi.languageTourGiven.code, tourBasicApi.languageTourGiven.name),
           new Country(tourBasicApi.country.id, tourBasicApi.country.code, tourBasicApi.country.name),
           new City(tourBasicApi.city.id, tourBasicApi.city.name),
           tourBasicApi.isFree,
           MathUtils.roundWithTwoDecimals(tourBasicApi.commission),
           TourUtils.getAffiliateLinksWithLanguageName(tourBasicApi.i18n),
           TourUtils.getTourStateFromApi(tourBasicApi.state)
         );

    }



    public static getTourResumeAdminFromApi(tourBasicApi: any, localeI18N: string): TourResumeAdmin {

         const tourResume: TourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);

         return new TourResumeAdmin(
             tourResume.id,
             tourResume.name,
             tourResume.nameMain,
             tourResume.descriptionShort,
             tourResume.descriptionShortMain,
             tourResume.languageTourGiven,
             tourResume.type,
             tourResume.country,
             tourResume.city,
             tourResume.duration,
             tourResume.isFree,
             tourResume.fares,
             tourResume.firstImage,
             tourResume.bid,
             tourResume.bidMin,
             tourResume.bidBlocked,
             tourResume.customCommissionFare,
             tourResume.defaultCommissionFare,
             tourBasicApi.customAffiliateCommission,
             tourBasicApi.customAffiliateCommissionBidFare,
             MathUtils.roundWithTwoDecimals(tourBasicApi.affiliateCommission),
             tourResume.state,
             tourBasicApi.providerId,
             tourBasicApi.providerName
         )

    }


    public static getTourFromApi(tourApi: any, tourI18NDisplayId: number): Tour {

         const languageMain: Language = new Language(tourApi.languageTourMain.id, tourApi.languageTourMain.code, tourApi.languageTourMain.name);

         if (!tourI18NDisplayId) {
           tourI18NDisplayId = languageMain.id;
         }

         //ATTRACTIONS
         const attractions: Array<Attraction> = new Array<Attraction>();
         tourApi.attractions.map((attraction: any) => {
           const name = TourUtils.getAttractionNameByLanguageId(attraction.id, tourI18NDisplayId, tourApi.i18n);
           const nameMain = TourUtils.getAttractionNameByLanguageId(attraction.id, languageMain.id, tourApi.i18n);

           attractions.push(new Attraction(attraction.id, name, nameMain));


         });

         //FARES
         const fares: Array<Fare> = new Array<Fare>();
         tourApi.fares.map((fare: any) => {
           const name = TourUtils.getFareNameByLanguageId(fare.id, tourI18NDisplayId, tourApi.i18n);
           const nameMain = TourUtils.getFareNameByLanguageId(fare.id, languageMain.id, tourApi.i18n);


           fares.push(new Fare(fare.id, name, nameMain, fare.price));
         });


         //SCHEDULES
         let fixed: SchedulePlanning = null;
         const seasons: Array<ScheduleSeason> = new Array<ScheduleSeason>();


         if (tourApi.schedule.type == ApiConstants.API_TOUR_SCHEDULE_TYPE_FIXED) {
           if (tourApi.schedule.fixed) {
            const fixedDays: Array<SchedulePlanningDay> = TourUtils.getSchedulePlanningDaysFromApiDays(tourApi.schedule.fixed.days);
            const fixedDayExceptions: Array<SchedulePlanningDay> = TourUtils.getSchedulePlanningExceptionDaysFromApiExceptionDays(tourApi.schedule.fixed.dayExceptions);
            fixed = new SchedulePlanning(fixedDays, fixedDayExceptions);
           }
         }

         if (tourApi.schedule.type == ApiConstants.API_TOUR_SCHEDULE_TYPE_SEASON) {
           if (tourApi.schedule.seasons) {
            tourApi.schedule.seasons.map((season: any) => {
               const days: Array<SchedulePlanningDay> = TourUtils.getSchedulePlanningDaysFromApiDays(season.details.days);
               const dayExceptions: Array<SchedulePlanningDay> = TourUtils.getSchedulePlanningExceptionDaysFromApiExceptionDays(season.details.dayExceptions);
               const details: SchedulePlanning = new SchedulePlanning(days, dayExceptions);
               seasons.push(new ScheduleSeason(CalendarUtils.fromFormattedDateToDDMMYYYY(season.startDay, AppConstants.APP_DATE_FORMAT_DD_MM_YYYY), CalendarUtils.fromFormattedDateToDDMMYYYY(season.finishDay, AppConstants.APP_DATE_FORMAT_DD_MM_YYYY), details));
            });
           }
         }

         const scheduleType: TourScheduleType = TourUtils.getScheduleTypeFromApi(tourApi.schedule.type);

         const schedule: Schedule = new Schedule(scheduleType, fixed, seasons);


         return new Tour(
         //const tour = new Tour(
           tourApi.id,
           TourUtils.getkeyI18NByLanguageId('name', tourI18NDisplayId, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('name', languageMain.id, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('description', tourI18NDisplayId, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('description', languageMain.id, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('descriptionShort', tourI18NDisplayId, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('descriptionShort', languageMain.id, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('additionalInformation', tourI18NDisplayId, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('additionalInformation', languageMain.id, tourApi.i18n),
           new Language(tourApi.languageTourGiven.id, tourApi.languageTourGiven.code, tourApi.languageTourGiven.name),
           new TourType(tourApi.type.id, tourApi.type.name),
           new Country(tourApi.country.id, tourApi.country.code, tourApi.country.name),
           new City(tourApi.city.id, tourApi.city.name),
           tourApi.duration,
           attractions,
           tourApi.minPeople,
           tourApi.maxPeople,
           tourApi.cancellationLimit,
           tourApi.bookingLimit,
           tourApi.maxPeoplePerBooking,
           tourApi.isFree,
           tourApi.maxFreePeople,
           tourApi.maxFreePeoplePrice,
           tourApi.maxFreePeopleAge,
           tourApi.locationCoordX,
           tourApi.locationCoordY,
           TourUtils.getkeyI18NByLanguageId('meetingPoint', tourI18NDisplayId, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('meetingPoint', languageMain.id, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('locationIndications', tourI18NDisplayId, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('locationIndications', languageMain.id, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('locationMoreIndications', tourI18NDisplayId, tourApi.i18n),
           TourUtils.getkeyI18NByLanguageId('locationMoreIndications', languageMain.id, tourApi.i18n),
           fares,
           schedule,
           languageMain,
           TourUtils.getLanguageI18NByLanguageId(tourI18NDisplayId, languageMain, tourApi.i18n),
           TourUtils.getLanguageI18NAlternatives(tourApi.i18n),
           tourApi.state
         );
    }

}
