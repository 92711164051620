import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { BookingBilling } from '../../models/billing/booking-billing.model';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { BillingUtils } from '../../models/billing/common/billing.utils';
import { ApiConstants } from '../../api/api.constants';
import { CustomerDetails } from '../../models/billing/customer-details.model';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { InvoiceFullDetails } from '../../models/billing/invoice-full-details.model';
import { AdminTourApiEndpointsUtils } from '../../api/endpoints/admin-tour-api-endponints.utils';
import { CompanyBillingDetailsView } from 'src/app/domain/views/billing/company-billing-details.view';

@Injectable({
     providedIn: 'root'
})
export class AdminBillingService extends BaseService {


     findBilling(dateInit: string, dateEnd: string, providerId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

          return super.sendGetRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminFindBillingEndpoint(dateInit, dateEnd, providerId, keywords, page),
               AdminTourApiEndpointsUtils.getAdminFindBillingEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const bookingBillings = new Array<BookingBilling>();

                    response.items.map((bookingBillingApi: any) => {

                         const bookingBilling: BookingBilling = new BookingBilling(
                              bookingBillingApi.orderBooking.id,
                              bookingBillingApi.orderBooking.event.tour.id,
                              bookingBillingApi.orderBooking.event.tour.i18n.translation.name,
                              CalendarUtils.fromFormattedDateToDDMMYYYY(bookingBillingApi.orderBooking.event.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
                              bookingBillingApi.orderBooking.event.hour,
                              ( ApiConstants.API_INVOICE_STATE_INVOICED == bookingBillingApi.state ) ? BillingUtils.getInvoiceFromApi(bookingBillingApi.invoice) : null,
                              BillingUtils.getBillingDetailsFromApi(bookingBillingApi.info),
                              new CustomerDetails(bookingBillingApi.customer.id, bookingBillingApi.customer.email, bookingBillingApi.customer.name, bookingBillingApi.customer.surname, bookingBillingApi.customer.phone),
                              BookingUtils.getStateFromApi(bookingBillingApi.orderBooking.state)
                         );

                         bookingBillings.push(bookingBilling);

                    })


                    return new ChunkGeneric<BookingBilling>(bookingBillings, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }




     findAffiliateBilling(dateInit: string, dateEnd: string, affiliateId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

          return super.sendGetRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminFindAffiliateBillingEndpoint(dateInit, dateEnd, affiliateId, keywords, page),
               AdminTourApiEndpointsUtils.getAdminFindAffiliateBillingEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const bookingBillings = new Array<BookingBilling>();


                    response.items.map((bookingBillingApi: any) => {

                         const bookingBilling: BookingBilling = new BookingBilling(
                              bookingBillingApi.orderBooking.id,
                              bookingBillingApi.orderBooking.event.tour.id,
                              bookingBillingApi.orderBooking.event.tour.i18n.translation.name,
                              CalendarUtils.fromFormattedDateToDDMMYYYY(bookingBillingApi.orderBooking.event.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
                              bookingBillingApi.orderBooking.event.hour,
                              ( ApiConstants.API_INVOICE_STATE_INVOICED == bookingBillingApi.state ) ? BillingUtils.getInvoiceFromApi(bookingBillingApi.invoice) : null,
                              BillingUtils.getBillingDetailsFromApi(bookingBillingApi.info),
                              new CustomerDetails(bookingBillingApi.customer.id, null, bookingBillingApi.customer.name, null, null),
                              BookingUtils.getStateAffiliateCommisionFromApi(bookingBillingApi.info.state)
                         );


                         bookingBillings.push(bookingBilling);

                    });

                    return new ChunkGeneric<BookingBilling>(bookingBillings, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }






     findGuideBilling(dateInit: string, dateEnd: string, providerId: number, guideId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

          return super.sendGetRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminFindGuideBillingEndpoint(dateInit, dateEnd, providerId, guideId, keywords, page),
               AdminTourApiEndpointsUtils.getAdminFindGuideBillingEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const bookingBillings = new Array<BookingBilling>();


                    response.items.map((bookingBillingApi: any) => {

                         const bookingBilling: BookingBilling = new BookingBilling(
                              bookingBillingApi.orderBooking.id,
                              bookingBillingApi.orderBooking.event.tour.id,
                              bookingBillingApi.orderBooking.event.tour.i18n.translation.name,
                              CalendarUtils.fromFormattedDateToDDMMYYYY(bookingBillingApi.orderBooking.event.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
                              bookingBillingApi.orderBooking.event.hour,
                              null,
                              BillingUtils.getBillingDetailsFromApi(bookingBillingApi.info),
                              new CustomerDetails(bookingBillingApi.customer.id, null, bookingBillingApi.customer.name, bookingBillingApi.customer.surname, null),
                              BookingUtils.getStateGuideCommisionFromApi(bookingBillingApi.info.state)
                         );


                         bookingBillings.push(bookingBilling);

                    });

                    return new ChunkGeneric<BookingBilling>(bookingBillings, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }





     findInvoices(providerId: number, minYear: number, minMonth: number, maxYear: number, maxMonth: number, page: number): Observable<ChunkGeneric<InvoiceFullDetails>> {

          return super.sendGetRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminFindInvoicesEndpoint(providerId, minYear, minMonth, maxYear, maxMonth, page),
               AdminTourApiEndpointsUtils.getAdminFindInvoicesEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const invoices = new Array<InvoiceFullDetails>();

                    response.items.map((invoiceApi: any) => {

                         const invoice = new InvoiceFullDetails(
                              null,
                              BillingUtils.getInvoiceFromApi(invoiceApi),
                              new CompanyBillingDetailsView(invoiceApi.provider.id, invoiceApi.provider.companyName)
                         );

                         invoices.push(invoice);

                    })

                    return new ChunkGeneric<InvoiceFullDetails>(invoices, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }



     findAffiliateInvoices(affiliateId: number, minYear: number, minMonth: number, maxYear: number, maxMonth: number, page: number): Observable<ChunkGeneric<InvoiceFullDetails>> {

          return super.sendGetRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminFindAffiliateInvoicesEndpoint(affiliateId, minYear, minMonth, maxYear, maxMonth, page),
               AdminTourApiEndpointsUtils.getAdminFindAffiliateInvoicesEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const invoices = new Array<InvoiceFullDetails>();

                    response.items.map((invoiceApi: any) => {

                         const invoice = new InvoiceFullDetails(
                              null,
                              BillingUtils.getInvoiceFromApi(invoiceApi),
                              new CompanyBillingDetailsView(invoiceApi.affiliate.id, invoiceApi.affiliate.companyName)
                         );

                         invoices.push(invoice);

                    })

                    return new ChunkGeneric<InvoiceFullDetails>(invoices, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }



     findInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

          return super.sendGetRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminFindInvoiceEndpoint(invoiceId),
               AdminTourApiEndpointsUtils.getAdminFindInvoiceEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {
                    return new InvoiceFullDetails(
                         BillingUtils.getInvoiceIssuerFromApi(response.issuer),
                         BillingUtils.getInvoiceFromApi(response),
                         new CompanyBillingDetailsView(response.provider.id, response.provider.companyName)
                    );

               })
          )
     }




     findAffiliateInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

          return super.sendGetRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminFindAffiliateInvoiceEndpoint(invoiceId),
               AdminTourApiEndpointsUtils.getAdminFindAffiliateInvoiceEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {
                    return new InvoiceFullDetails(
                         BillingUtils.getInvoiceIssuerFromApi(response.issuer),
                         BillingUtils.getInvoiceFromApi(response),
                         new CompanyBillingDetailsView(response.affiliate.id, response.affiliate.companyName)
                    );

               })
          )
     }




     invoiceDownload(invoiceId: number): Observable<string> {

          return super.sendBlobRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminInvoiceDownloadEndpoint(invoiceId),
               AdminTourApiEndpointsUtils.getAdminInvoiceDownloadEndpointAcceptVersion())
               .pipe(
                    map((response: any) => {

                         return response;

                    }
               )
          )
     }


     invoiceAffiliateDownload(invoiceId: number): Observable<string> {

          return super.sendBlobRequestWithRefresh(
               AdminTourApiEndpointsUtils.getAdminInvoiceAffiliateDownloadEndpoint(invoiceId),
               AdminTourApiEndpointsUtils.getAdminInvoiceAffiliateDownloadEndpointAcceptVersion())
               .pipe(
                    map((response: any) => {

                         return response;

                    }
               )
          )
     }




     invoicePaymentRemember(invoiceId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(
               AdminTourApiEndpointsUtils.getInvoicePaymentRememberEndpoint(invoiceId),
               {},
               AdminTourApiEndpointsUtils.getInvoicePaymentRememberEndpointAcceptVersion())
               .pipe(
                    map(() => {

                         return true;

                    }
               )
          )
     }



     invoicePaymentToProvider(invoiceId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(
               AdminTourApiEndpointsUtils.getInvoicePaymentToProviderEndpoint(invoiceId),
               {},
               AdminTourApiEndpointsUtils.getInvoicePaymentToProviderEndpointAcceptVersion())
               .pipe(
                    map(() => {

                         return true;

                    }
               )
          )
     }


     invoicePaymentToAffiliate(invoiceId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(
               AdminTourApiEndpointsUtils.getInvoicePaymentToAffiliateEndpoint(invoiceId),
               {},
               AdminTourApiEndpointsUtils.getInvoicePaymentToAffiliateEndpointAcceptVersion())
               .pipe(
                    map(() => {

                         return true;

                    }
               )
          )
     }

     invoiceSettleUp(invoiceId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(
               AdminTourApiEndpointsUtils.getInvoiceSettleUpEndpoint(invoiceId),
               {},
               AdminTourApiEndpointsUtils.getInvoiceSettleUpEndpointAcceptVersion())
               .pipe(
                    map(() => {

                         return true;

                    }
               )
          )
     }



     invoiceAffiliateSettleUp(invoiceId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(
               AdminTourApiEndpointsUtils.getInvoiceAffiliateSettleUpEndpoint(invoiceId),
               {},
               AdminTourApiEndpointsUtils.getInvoiceAffiliateSettleUpEndpointAcceptVersion())
               .pipe(
                    map(() => {

                         return true;

                    }
               )
          )
     }



}
