import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared.module';
import { GuideListBillingComponent } from './billing/list/list.component';
import { GuideDetailBookingComponent } from './booking/detail/detail.component';
import { GuideListBookingComponent } from './booking/list/list.component';
import { GuideComponent } from './guide.component';
import { GUIDE_ROUTES } from './guide.routes';
import { GuideProfileComponent } from './profile/profile.component';
import { GuideProfileSettingsComponent } from './profile/settings/settings.component';

@NgModule({
  declarations: [
       GuideComponent,
       GuideProfileComponent,
       GuideProfileSettingsComponent,
       GuideProfileSettingsComponent,
       GuideListBillingComponent,
       GuideListBookingComponent,
       GuideDetailBookingComponent       
  ],
  exports: [
  ],
  imports: [
    GUIDE_ROUTES,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxPaginationModule,
    NgbModule,
    NgxChartsModule
  ]
})
export class GuideModule { }
