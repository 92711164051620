import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { ProviderGuidesManagement } from 'src/app/business/models/provider/common/provider-guides-management.constants';

@Component({
     selector: 'app-sidebar',
     templateUrl: './sidebar.component.html'
})
export class SidebarComponent {

     public static SIDEBAR_ATTRIBUTE_HTML_ID = 'sidebar'

     @HostListener('document:click', ['$event'])
     documentClick(event: any) {
          if ( event.target.id != SidebarComponent.SIDEBAR_ATTRIBUTE_HTML_ID
              && !event.target.closest('.' + SidebarComponent.SIDEBAR_ATTRIBUTE_HTML_ID)
              && this.navbarOpen
         ) {
              this.navbarOpen = false;
         }
     }

     navbarOpen = false;
     showVar = false;

     isAdminRole = false;
     isProviderRole = false;
     isAffiliateRole = false;
     isGuideRole = false;
     statusActive: boolean = false;
     guidesManagement: number;

     guidesManagementFull:ProviderGuidesManagement  = ProviderGuidesManagement.Full;

     NavigationConstants = NavigationConstants;

     constructor(private sessionInteractor: SessionInteractor, private router: Router) {
          this.isAdminRole = this.sessionInteractor.isAdminRole();
          this.isProviderRole = this.sessionInteractor.isProviderRole();
          this.isAffiliateRole = this.sessionInteractor.isAffiliateRole();
          this.guidesManagement = this.sessionInteractor.loggedInUserDetails().guidesManagement;
          this.isGuideRole = this.sessionInteractor.isGuideRole();
     }

     goToDashboard() {
          this.router.navigate([NavigationConstants.getNavigationUserZone()]);
     }

     goToProviders() {
          this.router.navigate([NavigationConstants.getNavigationAdminListProviders()]);
     }

     goToAffiliates() {
          this.router.navigate([NavigationConstants.getNavigationAdminListAffiliates()]);
     }

     goToAdminTours() {
          this.router.navigate([NavigationConstants.getNavigationAdminListTours()]);
     }
     goToAdminReviews() {
          this.router.navigate([NavigationConstants.getNavigationAdminReviews()]);
     }

     goToAdminBilling() {
          this.router.navigate([NavigationConstants.getNavigationAdminBilling()]);
     }

     goToAdminAffiliateBilling() {
          this.router.navigate([NavigationConstants.getNavigationAdminAffiliateBilling()]);
     }

     goToAdminInvoices() {
          this.router.navigate([NavigationConstants.getNavigationAdminInvoices()]);
     }

     goToAdminAffiliationInvoices() {
          this.router.navigate([NavigationConstants.getNavigationAdminAffiliateInvoices()]);
     }

     goToTours() {
          this.router.navigate([NavigationConstants.getNavigationProviderListTours()]);
     }

     goToBooking() {
          this.router.navigate([NavigationConstants.getNavigationProviderListBooking()]);
     }

     goToAdminBooking() {
          this.router.navigate([NavigationConstants.getNavigationAdminListBooking()]);
     }

     goToBilling() {
          this.router.navigate([NavigationConstants.getNavigationProviderBilling()]);
     }


     goToAdminGuidesBilling() {
          this.router.navigate([NavigationConstants.getNavigationAdminGuideBilling()]);          
     }



     goToInvoices() {
          this.router.navigate([NavigationConstants.getNavigationProviderInvoices()]);
     }

     toggleNavbar() {
          this.navbarOpen = !this.navbarOpen;
     }

     goToHomeLanding() {
          this.router.navigate([NavigationConstants.getNavigationHome()]);
     }

     goToTutorial() {
          this.router.navigate([NavigationConstants.getNavigationProviderTutorial()]);
     }

     goToAffiliationDashboard() {
          this.router.navigate([NavigationConstants.getNavigationAffiliateDashboard()]);
     }

     goToAffiliationTours() {
          this.router.navigate([NavigationConstants.getNavigationAffiliateListTours()]);
     }

     goToAffiliationBilling() {
          this.router.navigate([NavigationConstants.getNavigationAffiliateBilling()]);
     }

     goToAffiliationInvoices() {
          this.router.navigate([NavigationConstants.getNavigationAffiliateInvoices()]);
     }

     goToProfile() {
          if ( this.isAdminRole ) {
               this.router.navigate([ NavigationConstants.getNavigationAdminProfile() ]);
          } else if ( this.isProviderRole ) {
               this.router.navigate([ NavigationConstants.getNavigationProviderProfile() ]);
          } else if ( this.isAffiliateRole ) {
               this.router.navigate([ NavigationConstants.getNavigationAffiliateProfile() ]);
          } else if ( this.isGuideRole ) {
               this.router.navigate([ NavigationConstants.getNavigationGuideProfile() ]);
          }
     }


     goToGuides() {
          this.router.navigate([NavigationConstants.getNavigationProviderListGuides()]);
     }

     goToProviderGuidesBilling() {
          this.router.navigate([NavigationConstants.getNavigationProviderGuideBilling()]);
     }




     goToGuideBookings() {
          this.router.navigate([NavigationConstants.getNavigationGuideListBookings()]);
     }

     goToGuideBilling() {
          this.router.navigate([NavigationConstants.getNavigationGuideBilling()]);
     }


     logout() {
          this.sessionInteractor.logout();
          this.router.navigate([NavigationConstants.NAVIGATION_LOGIN]);
     }

}
