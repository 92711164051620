import { TourFormView } from 'src/app/domain/views/provider/tour/tour-form.view';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { BookingUtils } from '../../models/master/booking/booking.utils';
import { CancelationReason } from '../../models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { ApiBuildUtils } from '../api.build.utils';
import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class AdminTourApiEndpointsUtils {


     public static getAdminFindToursEndpoint(search: string, countryId: number, cityId: number, providerId: number, page: number): string {
          return ApiUtils.prepareQueryRequest('admin/tours/', { 'search': search, 'countryId': countryId, 'cityId': cityId, 'providerId': providerId, 'page': page });
     }

     public static getAdminFindToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     // admin tour activate - deactivate
     public static getAdminActivateToursEndpoint(tourId: number): string {
          return `admin/tours/${tourId}/activate`;
     }

     public static getAdminActivateToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminDeactivateToursEndpoint(tourId: number): string {
          return `admin/tours/${tourId}/deactivate`;
     }

     public static getAdminDeactivateToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     // admin tour update bid
     public static getAdminTourUpdateBidEndpoint(tourId: number): string {
          return `admin/tours/${tourId}/bid`;
     }

     public static getAdminTourUpdateBidEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminTourUpdateBidEndpointObject(bid: number, bidBlocked: boolean): any {

          return {
               "block": bidBlocked,
               "bid": bid
          };
     }

     public static getAdminTourUpdateCommissionEndpoint(tourId: number): string {
          return `admin/tours/${tourId}/commission`;
     }

     public static getAdminTourUpdateCommissionEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminTourUpdateCommissionEndpointObject(commission: number): any {

          return {
               "customCommissionFare": commission,
          };
     }



     public static getAdminTourUpdateAffiliateCommissionEndpoint(tourId: number): string {
          return `admin/tours/${tourId}/affiliates/commission`;
     }

     public static getAdminTourUpdateAffiliateCommissionEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminTourUpdateAffiliateCommissionEndpointObject(isDefaultCommission: boolean, commission: number, isPercentage: boolean): any {
          const resultObject: any = {
               'customCommission': null,
               'customCommissionBidFare': null,
          }
          if ( !isDefaultCommission ) {
               if ( isPercentage ) {
                    resultObject.customCommissionBidFare = commission;
               } else {
                    resultObject.customCommission = commission;
               }
          }
          return resultObject;
     }


     public static getAdminCreateTourEndpoint(): string {
          return `admin/tours`;
     }

     public static getAdminCreateTourEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminUpdateTourEndpoint(tourId: number): string {
          return `admin/tours/${tourId}`;
     }

     public static getAdminUpdateTourEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminUpdateTourObject(tourForm: TourFormView): any {
          return ApiBuildUtils.buildRequestTourObject(tourForm);
     }

     public static getAdminUpdateTourI18NObject(tourForm: TourFormView): any {
          return ApiBuildUtils.buildRequestTourI18NObject(tourForm);
     }

     // admin tour detail
     public static getAdminTourDetailsEndpoint(tourId: number): string {
          return `admin/tours/${tourId}`;
     }

     public static getAdminTourDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminTourImagesEndpoint(tourId: number): string {
          return `admin/tours/${tourId}/images`;
     }

     public static getAdminTourImagesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminUploadImageTourEndpoint(tourId: number): string {
          return `admin/tours/${tourId}/images`;
     }

     public static getAdminUploadImageTourEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminTourDeleteImageEndpoint(tourId: number, imageId: number): string {
          return `admin/tours/${tourId}/images/${imageId}`;
     }

     public static getAdminTourDeleteImageEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminReviewsEndpoint(providerId: number, tourId: number, page: number): string {
          return ApiUtils.prepareQueryRequest('admin/tours/reviews', { 'providerId': providerId, 'tourId': tourId, 'page': page });
     }

     public static getAdminReviewsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminEditReviewsEndpoint(orderBookingId: number): string {
          return `admin/bookings/${orderBookingId}/rate`;
     }

     public static getAdminEditReviewsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminEditReviewsEndpointObject(comments: string, rating:  number): any {

          return {
               'comments': comments,
               'rate': rating
          }
     }




     public static getAdminFindBookEventsEndpoint(day: string, countryId: number, cityId: number, tourId: number, providerId: number, page: number): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return ApiUtils.prepareQueryRequest('admin/events/bookings', { 'day': dayFormatted, 'countryId': countryId, 'cityId': cityId, 'tourId': tourId, 'providerId': providerId, 'page': page });
     }

     public static getAdminFindBookEventsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminFindBookingsEndpoint(tourId: number, date: string, hour: string, keywords: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return ApiUtils.prepareQueryRequest(`admin/events/bookings/${tourId}/${dayFormatted}/${hour}`, { 'search': keywords });
     }

     public static getAdminFindBookingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminFindBillingEndpoint(dateInit: string, dateEnd: string, providerId: number, keywords: string, page: number): string {
          return ApiUtils.prepareQueryRequest('admin/billing', { 'minDay': dateInit, 'maxDay': dateEnd, 'providerId': providerId, 'search': keywords, 'page': page });
     }

     public static getAdminFindBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminFindAffiliateBillingEndpoint(dateInit: string, dateEnd: string, affiliateId: number, keywords: string, page: number): string {
          return ApiUtils.prepareQueryRequest('admin/billing/affiliates', { 'minDay': dateInit, 'maxDay': dateEnd, 'affiliateId': affiliateId, 'search': keywords, 'page': page });
     }

     public static getAdminFindAffiliateBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminFindGuideBillingEndpoint(dateInit: string, dateEnd: string, providerId: number, guideId: number, keywords: string, page: number): string {
          return ApiUtils.prepareQueryRequest('admin/billing/guides', { 'minDay': dateInit, 'maxDay': dateEnd, 'providerId': providerId, 'guideId': guideId, 'search': keywords, 'page': page });
     }

     public static getAdminFindGuideBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     public static getAdminUpdateBookingsContactDetailsEndpoint(bookId: number): string {
          return `admin/events/bookings/${bookId}`;
     }


     public static getAdminUpdateBookingsContactDetailsObject(email: string, name: string, surname: string, phone: string): any {
          return {
               "email": email,
               "name": name,
               "surname": surname,
               "phone": phone
          };
     }

     public static getAdminUpdateBookingsContactDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminResendBookConfirmationEmailEndpoint(bookId: number): string {
          return `admin/events/bookings/${bookId}/remember`;
     }

     public static getAdminResendBookConfirmationEmailEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     public static getAdminCancelBookingEndpoint(bookId: number): string {
          return `admin/events/bookings/${bookId}/cancel`;
     }

     public static getAdminCancelBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminCancelBookingEndpointObject(message: string, cancelationReason: CancelationReason): any {
          return {
               "type": BookingUtils.getApyTypeFromCancelationReason(cancelationReason),
               "reason": message
          };
     }



     public static getAdminUpdateBookingsEndpoint(tourId: number, date: string, hour: string, bookId: number): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `admin/events/bookings/${tourId}/${dayFormatted}/${hour}/${bookId}`;
     }

     public static getAdminUpdateBookingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminUpdateBookingsObject(isChildren: boolean, isAdult: boolean, orderFareId: number, shows: number): any {
          return {
               "isChildren": isChildren,
               "isAdult": isAdult,
               "orderFareId": orderFareId,
               "shows": shows
          };
     }



     public static getAdminFindInvoicesEndpoint(providerId: number, minYear: number, minMonth: number, maxYear: number, maxMonth: number, page: number): string {

          let minDate = null;
          if ( minYear != null && minMonth != null ) {
               minDate = minMonth + '-' + minYear;
          }

          let maxDate = null;
          if ( maxYear != null && maxMonth != null ) {
               maxDate = maxMonth + '-' + maxYear;
          }

          return ApiUtils.prepareQueryRequest('admin/billing/invoices', { 'providerId': providerId, 'page': page, 'minMonth': minDate, 'maxMonth': maxDate });
     }

     public static getAdminFindInvoicesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminFindAffiliateInvoicesEndpoint(affiliateId: number, minYear: number, minMonth: number, maxYear: number, maxMonth: number, page: number): string {

          let minDate = null;
          if ( minYear != null && minMonth != null ) {
               minDate = minMonth + '-' + minYear;
          }

          let maxDate = null;
          if ( maxYear != null && maxMonth != null ) {
               maxDate = maxMonth + '-' + maxYear;
          }

          return ApiUtils.prepareQueryRequest('admin/billing/affiliates/invoices', { 'affiliateId': affiliateId, 'page': page, 'minMonth': minDate, 'maxMonth': maxDate });
     }

     public static getAdminFindAffiliateInvoicesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminFindInvoiceEndpoint(invoiceId: number): string {
          return `admin/billing/invoices/${invoiceId}`;
     }

     public static getAdminFindInvoiceEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminFindAffiliateInvoiceEndpoint(invoiceId: number): string {
          return `admin/billing/affiliates/invoices/${invoiceId}`;
     }

     public static getAdminFindAffiliateInvoiceEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminInvoiceDownloadEndpoint(invoiceId: number): string {
          return `admin/billing/invoices/${invoiceId}`;
     }

     public static getAdminInvoiceDownloadEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1_PDF;
     }


     public static getAdminInvoiceAffiliateDownloadEndpoint(invoiceId: number): string {
          return `admin/billing/affiliates/invoices/${invoiceId}`;
     }

     public static getAdminInvoiceAffiliateDownloadEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1_PDF;
     }



     public static getInvoicePaymentRememberEndpoint(invoiceId: number): string {
          return `admin/billing/invoices/${invoiceId}/paymentremember`;
     }

     public static getInvoicePaymentRememberEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getInvoicePaymentToProviderEndpoint(invoiceId: number): string {
          return `admin/billing/invoices/${invoiceId}/payment`;
     }

     public static getInvoicePaymentToProviderEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getInvoicePaymentToAffiliateEndpoint(invoiceId: number): string {
          return `admin/billing/affiliates/invoices/${invoiceId}/payment`;
     }

     public static getInvoicePaymentToAffiliateEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getInvoiceSettleUpEndpoint(invoiceId: number): string {
          return `admin/billing/invoices/${invoiceId}/settledup`;
     }

     public static getInvoiceSettleUpEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getInvoiceAffiliateSettleUpEndpoint(invoiceId: number): string {
          return `admin/billing/affiliates/invoices/${invoiceId}/settledup`;
     }

     public static getInvoiceAffiliateSettleUpEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminBlockEventBookingEndpoint(tourId: number, date: string, hour: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `admin/events/${tourId}/${dayFormatted}/${hour}/block`;
     }

     public static getAdminBlockEventBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminBlockEventBookingEndpointObject(block: boolean): any {
          return {
               "block": block
          };
     }



     public static getAdminEventMaxBookingEndpoint(tourId: number, date: string, hour: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `admin/events/${tourId}/${dayFormatted}/${hour}`;
     }

     public static getAdminEventMaxBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminEventMaxBookingObject(maxBookings: number): any {
          return {
               "maxPeople": maxBookings
          };
     }

}


