<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'tourlist.Listado de tours' | translate }}</h2>
            <p>{{ 'tourlist.Estos son los tours que ofrece tu empresa' | translate }}</p>
        </div>
        <div class="col-12 col-sm-4 col-lg-6 col-xl-6 text-left text-sm-right px-0 px-lg-auto order-2 order-lg-3">
            <button class="btn btn-primary" name="Añadir tour" (click)="onCreateTourClick()">
                <img class="mr-2" src="assets/img/ic_add-white.svg">
                <span>{{ 'tourlist.Tour' | translate }}</span>
            </button>
        </div>
    </div>


    <div class="row dashboard-content-section mb-5 mb-md-auto">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                    <h2 class="font-weight-bold mb-4">{{ 'tourlist.Filtros' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <form class="form-boxes d-block d-md-flex m-0 p-0">
                    <div class="col-12 col-md-4 my-auto pr-md-3 pl-md-0 px-0">
                        <div class="field pb-2">
                            <input (keyup)="onSearchKeywordChanged()" [(ngModel)]="keywords" class="searcher-primary" type="search" placeholder="{{ 'tourlist.Busca tour por nombre o descripción' | translate }}" name="searcher">
                            <label for="search">{{ 'tourlist.Buscador' | translate }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 pl-md-0 pr-md-3 px-0">
                        <div class="field pb-2">
                            <select [(ngModel)]="countryId" (change)="onCountrySelected()" name="country">
                                <option [ngValue]="null">{{ 'tourlist.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                            </select>
                            <label for="country">{{ 'tourlist.País' | translate }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 px-0 px-md-auto">
                        <div class="field pb-2">
                            <select [(ngModel)]="cityId" (change)="onCitySelected()" name="city">
                                <option [ngValue]="null">{{ 'tourlist.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
                            </select>
                            <label for="city">{{ 'tourlist.Ciudad' | translate }}</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row empty-section" *ngIf="noResults">
        <div class="col-12 text-center my-auto">
            <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
            <p>{{ 'tourlist.No se han encontrado resultados' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section" *ngIf="!noResults">

        <div class="col-12 card card-list" *ngFor="let tourResume of tours | paginate: pagination;">
            <div class="row padding-lg-inside">
                <div class="col-12 col-lg-3 pl-0 pr-0 pr-lg-4">
                    <img class="img-card-list" src="{{ tourResume.firstImage?.medium.url | defaultPlaceholderImage : imageMediumSize }}">
                </div>
                <div class="col-12 col-lg-9 px-auto px-lg-0 padding-xs-inside">
                    <div class="row">
                        <div class="col-10 mt-0 mt-lg-2">
                            <h5 class="font-weight-bold">{{ tourResume.name | I18NTranslationSave: tourResume.nameMain }}</h5>
                            <p class="text-body-small grey-color">{{ tourResume.city.name }}, {{ tourResume.country.name }}</p>
                        </div>
                        <div class="col-2 text-right">
                            <label class="btn-more-vert dropdown">
                                <div class="dd-button">
                                    <img class="ic-mid" src="assets/img/ic_more-vert.svg">
                                </div>

                                <input type="checkbox" class="dd-input" id="test">

                                <ul class="dd-menu">

                                  <li>
                                      <a (click)="onGoToEditTour(tourResume.id)">{{ 'tourlist.Editar' | translate }}</a>
                                  </li>
                                  <li class="divider"></li>
                                  <li>
                                      <a (click)="onGoToManageGallery(tourResume.id)">{{ 'tourlist.Galería de imágenes' | translate }}</a>
                                  </li>
                                  <li class="divider"></li>
                                  <li>
                                    <a *ngIf="tourResume.state == tourStateActive" (click)="onTogleTourState(tourResume.id)">{{ 'tourlist.Cambiar estado a inactivo' | translate }}</a>
                                    <a *ngIf="tourResume.state != tourStateActive"  (click)="onTogleTourState(tourResume.id)">{{ 'tourlist.Cambiar estado a activo' | translate }}</a>
                                  </li>
                                  <li class="divider"></li>
                                  <li>
                                      <a (click)="onShowBid(tourResume)">{{ 'tourlist.Configurar puja' | translate }}</a>
                                  </li>
                                </ul>

                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="text-body-mid">{{ tourResume.descriptionShort | I18NTranslationSave: tourResume.descriptionShortMain }}</p>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6 col-sm-4 col-md-auto">
                            <p class="text-body-small grey-color font-weight-semibold">ID</p>
                            <p class="text-body-small font-weight-semibold">{{ tourResume.id }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-md-auto">
                            <p class="text-body-small grey-color font-weight-semibold">{{ 'tourlist.IDIOMA' | translate }}</p>
                            <p class="text-body-small font-weight-semibold">{{ tourResume.languageTourGiven.name }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-md-auto">
                            <p class="text-body-small grey-color font-weight-semibold">{{ 'tourlist.DURACIÓN' | translate }}</p>
                            <p class="text-body-small font-weight-semibold">{{ tourResume.duration }} minutos</p>
                        </div>

                        <div class="col-6 col-sm-4 col-md-auto">
                            <p class="text-body-small grey-color font-weight-semibold ">{{ 'tourlist.PRECIO' | translate }}</p>
                            <p *ngIf="tourResume.isFree" class="text-body-small font-weight-semibold">{{ 'tourlist.Gratis' | translate}}</p>
                            <p *ngIf="!tourResume.isFree" class="text-body-small font-weight-semibold">{{ 'tourlist.De pago' | translate}}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-md-auto ml-auto">
                            <p class="text-body-small grey-color font-weight-semibold">{{ 'tourlist.PUJA' | translate }}</p>
                            <p class="text-body-small primary-color opacity-4 font-weight-semibold text-left text-md-right">{{ tourResume.bid }} €</p>
                        </div>
                        <div class="col-6 col-sm-4 col-md-auto">
                            <p class="text-body-small grey-color font-weight-semibold">{{ 'tourlist.COMISIÓN' | translate }}</p>
                            <p class="text-body-small primary-color opacity-4 font-weight-semibold text-left text-md-right">{{ tourResume.customCommissionFare | tourCommissionFarePipe: tourResume.defaultCommissionFare}}%</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-4">
                            <span [ngClass]="'tag-primary' | tourStateClassPipe : tourResume.state">{{ 'tourlist.Tour inactivo' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <!-- Pagination -->

    <div class="row bg-grey-mid-color" *ngIf="!noResults">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>


    <app-message-panel [preloading]="false"></app-message-panel>


</div>