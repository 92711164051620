import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ProviderTourService } from 'src/app/business/services/provider/tour.service';
import { GenericException } from '../../exceptions/generic.exception';
import { TourFormView } from '../../views/provider/tour/tour-form.view';
import { Tour } from 'src/app/business/models/tour/tour.model';
import { Language } from 'src/app/business/models/master/language/language.model';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { ImageDetails } from 'src/app/business/models/image/image-details.model';
import { SessionInteractor } from '../session.interactor';


@Injectable({
  providedIn: 'root'
})
export class ProviderTourInteractor {

    constructor(private providerTourService: ProviderTourService, private sessionInteractor: SessionInteractor ) {
    }


    updateTour(tourForm: TourFormView, i18nTraslation: boolean): Observable<number> {

        if ( tourForm.id ) {
            if ( i18nTraslation ) {

                return Observable.create((observer: Observer<number>) => {
                      this.providerTourService.updateTourI18N(tourForm)
                            .subscribe(
                                (tourId: number) => {

                                    observer.next(tourId);
                                    observer.complete();
                                },
                                (error: GenericException) => {
                                    observer.error(error);
                                }
                            )

                      });

            } else {

                return Observable.create((observer: Observer<number>) => {
                      this.providerTourService.updateTour(tourForm)
                            .subscribe(
                                (tourId: number) => {

                                    observer.next(tourId);
                                    observer.complete();
                                },
                                (error: GenericException) => {
                                    observer.error(error);
                                }
                            )

                      });
            }

        } else {
              return Observable.create((observer: Observer<number>) => {
                    this.providerTourService.createTour(tourForm)
                          .subscribe(
                              (tourId: number) => {

                                  observer.next(tourId);
                                  observer.complete();
                              },
                              (error: GenericException) => {
                                  observer.error(error);
                              }
                          )

                    });
          }

    }



    getTour(tourId: number, tourI18NDisplay: number): Observable<Tour> {

        return Observable.create((observer: Observer<Tour>) => {
              this.providerTourService.getTour(tourId, tourI18NDisplay)
                    .subscribe(
                        (tour: Tour) => {

                            observer.next(tour);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }



    findTours(search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

        return Observable.create((observer: Observer<ChunkGeneric<TourResume>>) => {
              this.providerTourService.findTours(this.sessionInteractor.getCurrentLanguage(), search, countryId, cityId, page)
                    .subscribe(
                        (chunkTours: ChunkGeneric<TourResume>) => {

                            observer.next(chunkTours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }



    getTourGuivenLanguages(): Observable<Array<Language>> {

        return Observable.create((observer: Observer<Array<Language>>) => {
              this.providerTourService.getTourGuivenLanguages()
                    .subscribe(
                        (languages: Array<Language>) => {

                            observer.next(languages);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }

    activateTour(tourId: number): Observable<boolean> {
        return Observable.create((observer: Observer<boolean>) => {
              this.providerTourService.activateTour(tourId)
                    .subscribe(
                        () => {
                            observer.next(true);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }

    deactivateTour(tourId: number): Observable<boolean> {
        return Observable.create((observer: Observer<boolean>) => {
              this.providerTourService.deactivateTour(tourId)
                    .subscribe(
                        () => {
                            observer.next(true);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    uploadImageTour(tourId: number, image: File) {
        return Observable.create((observer: Observer<ImageDetails>) => {
              this.providerTourService.uploadImageTour(tourId, image)
                    .subscribe(
                        (tourImage: ImageDetails) => {
                            observer.next(tourImage);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }

    getTourImages(tourId: number) {
        return Observable.create((observer: Observer<Array<ImageDetails>>) => {
              this.providerTourService.getTourImages(tourId)
                    .subscribe(
                        (tourImages: Array<ImageDetails>) => {
                            observer.next(tourImages);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    deleteTourImage(tourId: number, imageId: number) {
        return Observable.create((observer: Observer<boolean>) => {
              this.providerTourService.deleteTourImage(tourId, imageId)
                    .subscribe(
                        () => {
                            observer.next(true);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    updateTourBid(tourId: number, bid: number) {
        return Observable.create((observer: Observer<boolean>) => {
              this.providerTourService.updateTourBid(tourId, bid)
                    .subscribe(
                        () => {
                            observer.next(true);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }




}
