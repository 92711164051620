import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class AdminGuideApiEndpointsUtils {


     public static getAdminGuidesNamesEndpoint(providerId: number): string {
          return ApiUtils.prepareQueryRequest('admin/guides/names', { 'providerId': providerId });
     }

     public static getAdminGuidesNamesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

}