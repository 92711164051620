import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './admin.component';
import { AdminDashboardComponent } from './dashboard/dashboard.component';
import { CreateProviderComponent } from './provider/create/create.component';
import { ListProvidersComponent } from './provider/list/list.component';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { AdminProfileComponent } from './profile/profile.component';
import { DetailProviderComponent } from './provider/detail/detail.component';
import { AdminAuthorizedGuard } from 'src/app/ui/guards/adminauthorized.guard';
import { AdminListToursComponent } from './tour/list/list.component';
import { AdminCreateTourComponent } from './tour/create/create.component';
import { AdminTourGalleryManagmentComponent } from './tour/gallery/gallery-managment.component';
import { AdminReviewComponent } from './review/review.component';
import { AdminListBillingComponent } from './billing/list/list.component';
import { AdminListInvoicesComponent } from './billing/invoices/list/list.component';
import { AdminInvoicdeDetailsComponent } from './billing/invoices/details/invoice-details.component';
import { AdminListBookingComponent } from './booking/list/list.component';
import { AdminDetailBookingComponent } from './booking/detail/detail.component';
import { ListAffiliatesComponent } from './affiliate/list/list.component';
import { DetailAffiliateComponent } from './affiliate/detail/detail.component';
import { AdminAffiliateListBillingComponent } from './affiliate/billing/list/list.component';
import { AdminAffiliateListInvoicesComponent } from './affiliate/billing/invoices/list/list.component';
import { AdminAffiliateInvoicdeDetailsComponent } from './affiliate/billing/invoices/details/invoice-details.component';
import { CreateAffiliateComponent } from './affiliate/create/create.component';
import { AdminGuideListBillingComponent } from './guide/billing/list/list.component';


const adminRoutes: Routes = [
    {
        path: NavigationConstants.NAVIGATION_ADMIN,
        component: AdminComponent,
        children: [
            { path: NavigationConstants.NAVIGATION_ADMIN_DASHBOARD, component: AdminDashboardComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_CREATE_PROVIDER, component: CreateProviderComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_LIST_PROVIDERS, component: ListProvidersComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_PROFILE, component: AdminProfileComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_PROVIDER_DETAIL, component: DetailProviderComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_LIST_TOURS, component: AdminListToursComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_REVIEW, component: AdminReviewComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_CREATE_TOUR, component: AdminCreateTourComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_UPDATE_TOUR, component: AdminCreateTourComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_TOUR_GALLERY_MANAGMENT, component: AdminTourGalleryManagmentComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_BILLING, component: AdminListBillingComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_INVOICES, component: AdminListInvoicesComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_INVOICES_DETAILS, component: AdminInvoicdeDetailsComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_LIST_BOOKING, component: AdminListBookingComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_DETAIL_BOOKING, component: AdminDetailBookingComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_LIST_AFFILIATES, component: ListAffiliatesComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_CREATE_AFFILIATE, component: CreateAffiliateComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_DETAIL, component: DetailAffiliateComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_BILLING, component: AdminAffiliateListBillingComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_INVOICES, component: AdminAffiliateListInvoicesComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_INVOICES_DETAILS, component: AdminAffiliateInvoicdeDetailsComponent },
            { path: NavigationConstants.NAVIGATION_ADMIN_GUIDE_BILLING, component: AdminGuideListBillingComponent }
        ],
        canActivate: [ AdminAuthorizedGuard ]
    }
];


export const ADMIN_ROUTES = RouterModule.forChild( adminRoutes );
