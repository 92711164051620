import { Injectable }                   from '@angular/core';
import { Observable, Observer }         from 'rxjs';

import { AdminTourService }             from 'src/app/business/services/admin/tour.service';
import { SessionInteractor }            from '../session.interactor';

import { GenericException }             from '../../exceptions/generic.exception';
import { ChunkGeneric }                 from '../../views/chunk-generic.view';
import { TourResumeAdmin }              from 'src/app/business/models/tour/tour-resume-admin.model';
import { TourFormView }                 from '../../views/provider/tour/tour-form.view';
import { ImageDetails }                 from 'src/app/business/models/image/image-details.model';
import { TourProvider } from 'src/app/business/models/tour/tour-provider.model';


@Injectable({
  providedIn: 'root'
})
export class AdminTourInteractor {


    constructor(private adminTourService: AdminTourService, private sessionInteractor: SessionInteractor ) {
    }



    findTours(search: string, countryId: number, cityId: number, providerId: number, page: number): Observable<ChunkGeneric<TourResumeAdmin>> {

        return Observable.create((observer: Observer<ChunkGeneric<TourResumeAdmin>>) => {
              this.adminTourService.findTours(this.sessionInteractor.getCurrentLanguage(), search, countryId, cityId, providerId, page)
                    .subscribe(
                        (chunkTours: ChunkGeneric<TourResumeAdmin>) => {

                            observer.next(chunkTours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }



    findAllToursByProvider(providerId: number): Observable<Array<TourResumeAdmin>> {

        return Observable.create((observer: Observer<Array<TourResumeAdmin>>) => {
              this.adminTourService.findTours(this.sessionInteractor.getCurrentLanguage(), null, null, null, providerId, null)
                    .subscribe(
                        (chunkTours: ChunkGeneric<TourResumeAdmin>) => {

                            observer.next(chunkTours.items);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }


    activateTour(tourId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
              this.adminTourService.activateTour(tourId)
                    .subscribe(
                        () => {
                            observer.next(true);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    deactivateTour(tourId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
              this.adminTourService.deactivateTour(tourId)
                    .subscribe(
                        () => {
                            observer.next(true);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    updateTourBid(tourId: number, bid: number, bidBlocked: boolean): Observable<TourResumeAdmin>  {

        return Observable.create((observer: Observer<TourResumeAdmin>) => {
                this.adminTourService.updateTourBid(this.sessionInteractor.getCurrentLanguage(), tourId, bid, bidBlocked)
                    .subscribe(
                        (tourResumeAdmin: TourResumeAdmin) => {

                            observer.next(tourResumeAdmin);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    updateTourCommission(tourId: number, commission: number): Observable<TourResumeAdmin>  {

        return Observable.create((observer: Observer<TourResumeAdmin>) => {
                this.adminTourService.updateTourCommission(this.sessionInteractor.getCurrentLanguage(), tourId, commission)
                    .subscribe(
                        (tourResumeAdmin: TourResumeAdmin) => {

                            observer.next(tourResumeAdmin);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    updateTourAffiliateCommission(tourId: number, isDefaultCommission: boolean, commission: number, isPercentage: boolean): Observable<TourResumeAdmin>  {

        return Observable.create((observer: Observer<TourResumeAdmin>) => {
                this.adminTourService.updateTourAffiliateCommission(this.sessionInteractor.getCurrentLanguage(), tourId, isDefaultCommission, commission, isPercentage)
                    .subscribe(
                        (tourResumeAdmin: TourResumeAdmin) => {

                            observer.next(tourResumeAdmin);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    getTour(tourId: number, tourI18NDisplay: number): Observable<TourProvider> {

        return Observable.create((observer: Observer<TourProvider>) => {
              this.adminTourService.getTour(tourId, tourI18NDisplay)
                    .subscribe(
                        (tour: TourProvider) => {

                            observer.next(tour);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }


    updateTour(tourForm: TourFormView, i18nTraslation: boolean): Observable<number> {

          if ( tourForm.id ) {
             if ( i18nTraslation ) {

                 return Observable.create((observer: Observer<number>) => {
                         this.adminTourService.updateTourI18N(tourForm)
                             .subscribe(
                                 (tourId: number) => {

                                     observer.next(tourId);
                                     observer.complete();
                                 },
                                 (error: GenericException) => {
                                     observer.error(error);
                                 }
                             )

                         });

             } else {

                 return Observable.create((observer: Observer<number>) => {
                         this.adminTourService.updateTour(tourForm)
                             .subscribe(
                                 (tourId: number) => {

                                     observer.next(tourId);
                                     observer.complete();
                                 },
                                 (error: GenericException) => {
                                     observer.error(error);
                                 }
                             )

                         });
             }
        } else {
             return Observable.create((observer: Observer<number>) => {
                   this.adminTourService.createTour(tourForm)
                         .subscribe(
                             (tourId: number) => {

                                 observer.next(tourId);
                                 observer.complete();
                             },
                             (error: GenericException) => {
                                 observer.error(error);
                             }
                         )

                   });
        }

    }


    getTourImages(tourId: number) {
        return Observable.create((observer: Observer<Array<ImageDetails>>) => {
              this.adminTourService.getTourImages(tourId)
                    .subscribe(
                        (tourImages: Array<ImageDetails>) => {
                            observer.next(tourImages);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    uploadImageTour(tourId: number, image: File) {
        return Observable.create((observer: Observer<ImageDetails>) => {
              this.adminTourService.uploadImageTour(tourId, image)
                    .subscribe(
                        (tourImage: ImageDetails) => {
                            observer.next(tourImage);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    deleteTourImage(tourId: number, imageId: number) {
        return Observable.create((observer: Observer<boolean>) => {
              this.adminTourService.deleteTourImage(tourId, imageId)
                    .subscribe(
                        () => {
                            observer.next(true);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


}
