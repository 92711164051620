import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { TourResume } from '../../models/tour/tour-resume.model';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { GuideTourApiEndpointsUtils } from '../../api/endpoints/guide-tour-api-endponints.utils';


@Injectable({
     providedIn: 'root'
})
export class GuideTourService extends BaseService {


    findToursForReselling(localeI18N: string, search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

      return super.sendGetRequestWithRefresh(GuideTourApiEndpointsUtils.getFindToursForResellingEndpoint(search, countryId, cityId, page), GuideTourApiEndpointsUtils.getFindToursForResellingEndpointAcceptVersion())
        .pipe(
          map((response: any) => {

            const toursResume = new Array<TourResume>();

            response.items.map((tourBasicApi: any) => {

              const tourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);
              toursResume.push(tourResume);
            })

            return new ChunkGeneric<TourResume>(toursResume, new Pagination(response.total, response.currentPage, response.chunkSize));

          })
        )
    }


}
