import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CalendarUtils }                from 'src/app/domain/utils/calendar-utils';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';
import { Pagination }                   from 'src/app/domain/views/pagination.view';

import { BookingBilling }               from 'src/app/business/models/billing/booking-billing.model';

import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';


import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { FindBillingFilterView } from 'src/app/domain/views/billing/find-invoices-filter.view';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';


@Component({
     selector: 'app-list-billing',
     templateUrl: './list.component.html'
})
export class ListBillingComponent implements OnInit {

     @Output() findBillingTrigger = new EventEmitter<FindBillingFilterView>();
     @Output() companySelectedTrigger = new EventEmitter<number>();

     @Input()
     set chunkBookingBilling(chunkBookingBilling: ChunkGeneric<BookingBilling>) {
          if ( chunkBookingBilling ) {
               this.pagination = new Pagination(chunkBookingBilling.pagination.totalItems, chunkBookingBilling.pagination.currentPage, chunkBookingBilling.pagination.itemsPerPage);
               this.pageNumber = this.pagination.currentPage;
               this.bookingBillings = chunkBookingBilling.items;
               this.noResults = this.bookingBillings.length == 0;
          }
     }

     @Input() isAdmin: boolean;
     @Input() isProvider: boolean;
     @Input() isAffiliate: boolean;
     @Input() isGuide: boolean;

     @Input() isAffiliateBilling: boolean;
     @Input() isGuideBilling: boolean;

     @Input() companyNames: Array<CompanyName>;
     @Input() guideNames: Array<CompanyName>

     companyId: number;
     guideId: number;
     pageNumber: number;

     pagination: Pagination;

     bookingBillings: Array<BookingBilling>;

     noResults: boolean;

     desktopVersion: boolean;
     initialWindowSize: number;

     // dateRange
     hoveredDate: NgbDate;

     fromDate: NgbDate;
     toDate: NgbDate;

     dateInit: string;
     dateEnd: string;

     calendarDisplayMonths: number;

     keywords: string;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     constructor(
          private calendar: NgbCalendar,
          private translateService: TranslateService,
          public formatter: NgbDateParserFormatter) {

          this.pagination = new Pagination(0, 1, 20);
          this.pageNumber = this.pagination.currentPage;
          this.noResults = true;

          this.calendarDisplayMonths = 2;

          this.desktopVersion = true;

          this.fromDate = CalendarUtils.convertNgbDateToFirstDayOfMonthNgDate(calendar.getToday());
          this.toDate = calendar.getToday();

          this.dateInit = CalendarUtils.formatNgbDateToString(this.fromDate);
          this.dateEnd = CalendarUtils.formatNgbDateToString(this.toDate);

     }



     ngOnInit() {

          this.initialWindowSize = window.innerWidth;

          this.findBilling();

          this.translateService.get(I18N('pagination.Siguiente')).subscribe(
               (messageTranslated: string) => {
                    this.paginationNextLabel = messageTranslated;
               }
         )

         this.translateService.get(I18N('pagination.Previous')).subscribe(
               (messageTranslated: string) => {
                    this.paginationPreviousLabel = messageTranslated;
               }
         )

     }

     onDesktopVersionUpdated( isDesktopVersion: boolean ) {
          this.desktopVersion = isDesktopVersion;
          if ( this.desktopVersion ) {
               this.calendarDisplayMonths = 2;
          } else {
               this.calendarDisplayMonths = 1;
          }
     }

     findBilling() {
          this.findBillingTrigger.emit(new FindBillingFilterView(this.dateInit, this.dateEnd, this.keywords, this.pageNumber, this.companyId, this.guideId));
     }


     onPageChanged(currentPage: number) {
          this.pageNumber = currentPage;
          this.findBilling();
     }


     onDateSelection(date: NgbDate) {
          let parsed = '';
          if (!this.fromDate && !this.toDate) {
               this.fromDate = date;

               parsed = CalendarUtils.formatNgbDateToString(this.fromDate);
               this.dateInit = parsed;

          } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
               this.toDate = date;

               parsed = CalendarUtils.formatNgbDateToString(this.toDate);
               this.dateEnd = parsed;

          } else {
               this.toDate = null;
               this.fromDate = date;

               parsed = CalendarUtils.formatNgbDateToString(this.fromDate);
               this.dateInit = parsed;

          }
          this.findBilling();
     }

     onSearchKeywordChanged() {
       this.findBilling();
     }

     onCompanySelected() {
          this.companySelectedTrigger.emit(this.companyId);
          this.findBilling();
     }

     onGuideSelected() {
          this.findBilling();
     }

     isHovered(date: NgbDate) {
          return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
     }

     isInside(date: NgbDate) {
          return date.after(this.fromDate) && date.before(this.toDate);
     }

     isRange(date: NgbDate) {
          return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
     }

     validateInput(currentValue: NgbDate, input: string): NgbDate {
          const parsed = this.formatter.parse(input);
          return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;

     }


}
