import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { ADMIN_ROUTES } from './admin.routes';
import { AdminDashboardComponent } from './dashboard/dashboard.component';
import { CreateProviderComponent } from './provider/create/create.component';
import { ListProvidersComponent } from './provider/list/list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {NgxPaginationModule} from 'ngx-pagination';
import { SharedModule } from 'src/app/shared.module';
import { AdminProfileComponent } from './profile/profile.component';
import { AdminProviderStateClassPipe } from '../../pipes/admin/admin-provider-state-class.pipe';
import { AdminProviderStateNamePipe } from '../../pipes/admin/admin-provider-state-name.pipe';
import { ProvidersCardsComponent } from './provider/list/components/cards/cards.component';
import { ProvidersTableComponent } from './provider/list/components/table/table.component';
import { DetailProviderComponent } from './provider/detail/detail.component';
import { AdminListToursComponent } from './tour/list/list.component';
import { AdminCreateTourComponent } from './tour/create/create.component';
import { AdminTourGalleryManagmentComponent } from './tour/gallery/gallery-managment.component';
import { AdminProfileSettingsComponent } from './profile/settings/settings.component';
import { AdminEditTourBidModalComponent } from './tour/list/edit-bid/edit-bid-modal.component';
import { AdminReviewComponent } from './review/review.component';
import { AdminEditReviewModalComponent } from './review/edit-review/edit-review-modal.component';
import { AdminListBillingComponent } from './billing/list/list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminEditTourCommissionModalComponent } from './tour/list/edit-commission/edit-commission-modal.component';
import { AdminListInvoicesComponent } from './billing/invoices/list/list.component';
import { AdminInvoicesTableComponent } from './billing/invoices/list/components/table/table.component';
import { AdminInvoicesCardsComponent } from './billing/invoices/list/components/cards/cards.component';
import { AdminInvoicdeDetailsComponent } from './billing/invoices/details/invoice-details.component';
import { CreateTourProviderComponent } from './tour/create/provider/provider.component';
import { AdminListBookingComponent } from './booking/list/list.component';
import { AdminDetailBookingComponent } from './booking/detail/detail.component';
import { ListAffiliatesComponent } from './affiliate/list/list.component';
import { AffiliatesTableComponent } from './affiliate/list/components/table/table.component';
import { AffiliatesCardsComponent } from './affiliate/list/components/cards/cards.component';
import { DetailAffiliateComponent } from './affiliate/detail/detail.component';
import { AdminAffiliateStateClassPipe } from '../../pipes/admin/admin-affiliate-state-class.pipe';
import { AdminAffiliateStateNamePipe } from '../../pipes/admin/admin-affiliate-state-name.pipe';
import { AdminEditTourAffiliateCommisionModalComponent } from './tour/list/edit-commission-affiliate/edit-commission-affiliate-modal.component';
import { AdminAffiliateListBillingComponent } from './affiliate/billing/list/list.component';
import { AdminAffiliateListInvoicesComponent } from './affiliate/billing/invoices/list/list.component';
import { AdminAffiliateInvoicesTableComponent } from './affiliate/billing/invoices/list/components/table/table.component';
import { AdminAffiliateInvoicesCardsComponent } from './affiliate/billing/invoices/list/components/cards/cards.component';
import { AdminAffiliateInvoicdeDetailsComponent } from './affiliate/billing/invoices/details/invoice-details.component';
import { CreateAffiliateComponent } from './affiliate/create/create.component';
import { AdminGuideListBillingComponent } from './guide/billing/list/list.component';


@NgModule({
  declarations: [
    AdminComponent,
    AdminDashboardComponent,
    CreateProviderComponent,
    ListProvidersComponent,
    ProvidersCardsComponent,
    ProvidersTableComponent,
    AdminProfileComponent,
    AdminProviderStateClassPipe,
    AdminProviderStateNamePipe,
    DetailProviderComponent,
    AdminListToursComponent,
    AdminReviewComponent,
    AdminCreateTourComponent,
    AdminTourGalleryManagmentComponent,
    AdminProfileSettingsComponent,
    AdminEditTourBidModalComponent,
    AdminEditReviewModalComponent,
    AdminListBillingComponent,
    AdminAffiliateListBillingComponent,
    AdminEditTourCommissionModalComponent,
    AdminListInvoicesComponent,
    CreateAffiliateComponent,
    AdminAffiliateListInvoicesComponent,
    AdminAffiliateInvoicesTableComponent,
    AdminAffiliateInvoicesCardsComponent,
    AdminAffiliateInvoicdeDetailsComponent,
    AdminInvoicesTableComponent,
    AdminInvoicesCardsComponent,
    AdminInvoicdeDetailsComponent,
    CreateTourProviderComponent,
    AdminListBookingComponent,
    AdminDetailBookingComponent,
    ListAffiliatesComponent,
    AffiliatesTableComponent,
    AffiliatesCardsComponent,
    DetailAffiliateComponent,
    AdminAffiliateStateClassPipe,
    AdminAffiliateStateNamePipe,
    AdminEditTourAffiliateCommisionModalComponent,
    AdminGuideListBillingComponent
  ],
  exports: [
    AdminComponent,
    AdminDashboardComponent,
    CreateProviderComponent,
    ListProvidersComponent,
    AdminProfileComponent,
    AdminListToursComponent,
    AdminReviewComponent,
    AdminListBillingComponent,
    AdminCreateTourComponent,
    AdminTourGalleryManagmentComponent
  ],
  imports: [
    ADMIN_ROUTES,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgbModule,
    NgxPaginationModule
  ]
})
export class AdminModule { }
