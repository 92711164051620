import { BillingDetails } from '../billing-details.model'
import { InvoiceDetails } from '../invoice-details.model'
import { InvoiceIssuer } from '../invoice-issuer.model'

export class BillingUtils {


  public static getBillingDetailsFromApi(billingDetailsApi: any): BillingDetails {


    return new BillingDetails(
      billingDetailsApi.people,
      billingDetailsApi.shows,
      billingDetailsApi.price,
      billingDetailsApi.payment,
      billingDetailsApi.commission
    )

  }

  public static getInvoiceFromApi(invoiceApi: any): InvoiceDetails {

    return new InvoiceDetails(
      invoiceApi.id,
      invoiceApi.serialNumber,
      invoiceApi.invoiceDate,
      invoiceApi.invoiceYearPeriod,
      invoiceApi.invoiceMonthPeriod,
      new BillingDetails(
        invoiceApi.people,
        invoiceApi.shows,
        invoiceApi.price,
        invoiceApi.payment,
        invoiceApi.commission
      ),
      invoiceApi.chargePending,
      invoiceApi.paymentPending,
      invoiceApi.chargeCompleted,
      invoiceApi.paymentCompleted,
      invoiceApi.settledUp,
    )

  }



  public static getInvoiceIssuerFromApi(invoiceIssuerApi: any): InvoiceIssuer {

    return new InvoiceIssuer(
      invoiceIssuerApi.name,
      invoiceIssuerApi.registration,
      invoiceIssuerApi.vat,
      invoiceIssuerApi.email,
      invoiceIssuerApi.address1,
      invoiceIssuerApi.address2
    )

  }

}
