<table class="table table-hover table-dark">
    <thead class="thead-dark">
        <tr>
            <th scope="col">{{ 'billinglist.Fecha' | translate }}</th>
            <th scope="col">{{ 'billinglist.Nombre tour' | translate }}</th>
            <th scope="col">{{ 'billinglist.Datos reserva' | translate }}</th>
            <th *ngIf="!isAffiliateBilling && !isGuideBilling" scope="col" class="text-center">{{ 'billinglist.Pax' | translate }}</th>
            <th *ngIf="!isAffiliateBilling && !isGuideBilling" scope="col" class="text-center">{{ 'billinglist.Show' | translate }}</th>
            <th *ngIf="isGuideBilling" scope="col" class="text-center">{{ 'billinglist.Personas' | translate }}</th>
            <th *ngIf="!isGuideBilling" scope="col" class="text-center">{{ 'billinglist.Comisión' | translate }}</th>
            <th *ngIf="!isAffiliateBilling && !isGuideBilling" scope="col" class="text-center">{{ 'billinglist.Price' | translate }}</th>
            <th scope="col" class="text-center">{{ 'billinglist.Estado' | translate }}</th>
            <th *ngIf="!isGuideBilling" scope="col" class="text-center">{{ 'billinglist.Facturación' | translate }}</th>
        </tr>
    </thead>
    <tbody class="">

        <tr *ngFor="let bookingBilling of bookingBillings | paginate: pagination;">
            <td scope="row ">{{ bookingBilling.tourDate }} | {{ bookingBilling.tourHour }}</td>
            <td> {{ bookingBilling.tourNameTranslated }}</td>
            <td>
               {{ bookingBilling.bookingId }} - {{ bookingBilling.customerDetails.name }}
               <span *ngIf="!isAffiliateBilling" >{{ bookingBilling.customerDetails.surname }}</span>
            </td>
            <td *ngIf="!isAffiliateBilling" class="text-center">{{ bookingBilling.billingDetails.people }}</td>
            <td *ngIf="!isAffiliateBilling && !isGuideBilling" class="text-center">{{ bookingBilling.billingDetails.shows }}</td>
            <td *ngIf="!isGuideBilling" class="text-center">{{ bookingBilling.billingDetails.commission }} &euro;</td>
            <td *ngIf="!isAffiliateBilling && !isGuideBilling" class="font-weight-bold" class="text-center">{{ bookingBilling.billingDetails.price }} &euro;</td>
            <td class="text-center">{{ '' | billingStateBooking: bookingBilling.bookingState | translate }}</td>
            <td *ngIf="!isGuideBilling && bookingBilling.invoiceDetails" class="text-center">
                 <div class="status normal">
                    <div class="dot"></div> {{ 'billinglist.Completada' | translate }}
                 </div>
            </td>
            <td *ngIf="!isGuideBilling && !bookingBilling.invoiceDetails" class="text-center">
                 <div class="status remove">
                    <div class="dot"></div> {{ 'billinglist.Pendiente' | translate }}
                 </div>
            </td>

        </tr>

    </tbody>
</table>