import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { SessionInteractor } from '../session.interactor';
import { GuideTourService } from 'src/app/business/services/guide/tour.service';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';


@Injectable({
     providedIn: 'root'
})
export class GuideTourInteractor {

     constructor(private guideTourService: GuideTourService, private sessionInteractor: SessionInteractor) {
     }

     findToursForReselling(search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

        return Observable.create((observer: Observer<ChunkGeneric<TourResume>>) => {
              this.guideTourService.findToursForReselling(this.sessionInteractor.getCurrentLanguage(), search, countryId, cityId, page)
                    .subscribe(
                        (chunkTours: ChunkGeneric<TourResume>) => {

                            observer.next(chunkTours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }
}
