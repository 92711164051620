import { RRule, Weekday } from 'rrule';
import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';
import { DAYS_OF_WEEK } from 'angular-calendar';
import * as moment from 'moment-timezone';

import { endOfYear, startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';
import { AppConstants } from 'src/app/domain/common/app.constants';

export class CalendarUtils {

    public static fromCalendarRuleWeekdayToTourWeekday(ruleWeekday: Weekday): TourScheduleWeekdaysType {
        switch ( ruleWeekday ) {
            case RRule.MO:
                return TourScheduleWeekdaysType.Monday;
            case RRule.TU:
                return TourScheduleWeekdaysType.Tuesday;
            case RRule.WE:
                return TourScheduleWeekdaysType.Wednesday;
            case RRule.TH:
                return TourScheduleWeekdaysType.Thursday;
            case RRule.FR:
                return TourScheduleWeekdaysType.Friday;
            case RRule.SA:
                return TourScheduleWeekdaysType.Saturday;
            case RRule.SU:
                return TourScheduleWeekdaysType.Sunday;
            default:
                return null;
        }
    }


    public static fromStringToTourWeekday(weekday: any): TourScheduleWeekdaysType {
        if ( weekday === 1 ) {
            return TourScheduleWeekdaysType.Monday;
        } else if ( weekday === 2 ) {
            return TourScheduleWeekdaysType.Tuesday;
        } else if ( weekday === 3 ) {
            return TourScheduleWeekdaysType.Wednesday;
        } else if ( weekday === 4 ) {
            return TourScheduleWeekdaysType.Thursday;
        } else if ( weekday === 5 ) {
            return TourScheduleWeekdaysType.Friday;
        } else if ( weekday === 6 ) {
            return TourScheduleWeekdaysType.Saturday;
        } else if ( weekday === 7 ) {
            return TourScheduleWeekdaysType.Sunday;
        } else {
            return null;
        }

    }





    public static fromTourWeekdayToCalendarRuleWeekday(tourWeekday: TourScheduleWeekdaysType): Weekday {
        switch ( tourWeekday ) {
            case TourScheduleWeekdaysType.Monday:
                return RRule.MO;
            case TourScheduleWeekdaysType.Tuesday:
                return RRule.TU;
            case TourScheduleWeekdaysType.Wednesday:
                return RRule.WE;
            case TourScheduleWeekdaysType.Thursday:
                return RRule.TH;
            case TourScheduleWeekdaysType.Friday:
                return RRule.FR;
            case TourScheduleWeekdaysType.Saturday:
                return RRule.SA;
            case TourScheduleWeekdaysType.Sunday:
                return RRule.SU;
            default:
                return null;
        }
    }


    public static fromCalendarWeekdayToTourWeekday(calendarDayOfWeek: DAYS_OF_WEEK): TourScheduleWeekdaysType {
        switch ( calendarDayOfWeek ) {
            case DAYS_OF_WEEK.MONDAY:
                return TourScheduleWeekdaysType.Monday;
            case DAYS_OF_WEEK.TUESDAY:
                return TourScheduleWeekdaysType.Tuesday;
            case DAYS_OF_WEEK.WEDNESDAY:
                return TourScheduleWeekdaysType.Wednesday;
            case DAYS_OF_WEEK.THURSDAY:
                return TourScheduleWeekdaysType.Thursday;
            case DAYS_OF_WEEK.FRIDAY:
                return TourScheduleWeekdaysType.Friday;
            case DAYS_OF_WEEK.SATURDAY:
                return TourScheduleWeekdaysType.Saturday;
            case DAYS_OF_WEEK.SUNDAY:
                return TourScheduleWeekdaysType.Sunday;
            default:
                return null;
        }
    }

    public static fromDateToDaleLocalizedDDMMYYYY(date: Date): string {
        if ( date ) {
            return moment(date).format('DD/MM/YYYY');
        }
        return '';
    }
    public static fromDaleLocalizedDDMMYYYYToDate(date: string): Date {
        if ( date && date != '' ) {
          return moment(date, 'DD/MM/YYYY').toDate();
        }
        return null;
    }

    public static fromDaleLocalizedMMYYToDate(date: string): Date {
        if ( date && date != '' ) {
          return moment(date, 'MM/YY').toDate();
        }
        return null;
    }

    public static fromDateHtmlInputToDate(date: string): Date {
        if ( date && date != '' ) {
          return moment(date, 'YYYY-MM-DD').toDate();
        }
        return null;
    }

    public static fromDateHtmlInputToDateDDMMYYYY(date: string): string {
        if ( date ) {
            return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        return '';
    }

    public static fromDateDDMMYYYYToDateHtmlInput(date: string): string {
        if ( date ) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        return '';
    }

    public static fromDateToUTCDate(date: Date): Date {
        if ( date ) {
          return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
        }
        return null;
    }


    public static fromDateDDMMYYYYToFormatString(date: string, format: string): string {
        if ( date ) {
            return moment(date, AppConstants.APP_DATE_FORMAT_DD_MM_YYYY).format(format);
        }
        return '';
    }

    public static fromFormattedDateToDDMMYYYY(date: Date, format: string): string {
        if ( date ) {
            return moment(date, format).format(AppConstants.APP_DATE_FORMAT_DD_MM_YYYY);
        }
        return '';
    }


    public static fromDateToFormatString(date: Date, format: string): string {
        if ( date ) {
            return moment(date).format(format);
        }
        return '';
    }


    public static getCurrentDate(): Date {
        return moment().toDate();
    }

    public static getCurrentYear(): number {
        return moment().toDate().getFullYear();
    }

    public static getCurrentMonth(initOne: boolean): number {
        const month = moment().toDate().getMonth();
        if ( initOne ) {
             return month + 1;
        }

        return month;
    }

    public static getDateWithExtraYears(date: Date, years: number): Date {
        return moment(date).add(years, 'year').toDate();
    }

    public static getDateWithExtraDays(date: Date, days: number): Date {
        return moment(date).add(days, 'days').toDate();
    }

    public static getStartofDay(date: Date): Date {
        return startOfDay(date);
    }

    public static getEndOfDay(date: Date): Date {
        return endOfDay(date);
    }

    public static getEndOfYear(date: Date): Date {
        return endOfYear(date);
    }

    public static getStartOfMonth(date: Date): Date {
        return startOfMonth(date);
    }

    public static getEndOfMonth(date: Date): Date {
        return endOfMonth(date);
    }



    public static getYearsAvailablesForBilling(): Array<number> {

         const availableYears: Array<number> = new Array<number>();

         for (let availableYear = AppConstants.BILLING_FIRST_DATE_YEAR; availableYear <= CalendarUtils.getCurrentYear(); availableYear++) {
             availableYears.push(availableYear);
         }

         return availableYears;
    }


    public static getMonthsAvailablesForBillingFromYear(year: number): Array<number> {

         const availableMonths: Array<number> = new Array<number>();

         let minMonth = 1;
         if ( AppConstants.BILLING_FIRST_DATE_YEAR == year ) {
              minMonth = AppConstants.BILLING_FIRST_DATE_MONTH;
         }

         let maxMonth = 12;
         if ( CalendarUtils.getCurrentYear() == year ) {
              maxMonth = CalendarUtils.getCurrentMonth(true);
         }

         for (let availableMonth = minMonth; availableMonth <= maxMonth; availableMonth++) {
             availableMonths.push(availableMonth);
         }

         return availableMonths;
    }


    public static getFirstMonthsAvailablesForBillingFromYear(year: number): number {

         if ( AppConstants.BILLING_FIRST_DATE_YEAR == year ) {
              return AppConstants.BILLING_FIRST_DATE_MONTH;
         }

         return 1;
    }


    public static getLastMonthsAvailablesForBillingFromYear(year: number): number {

         if ( CalendarUtils.getCurrentYear() == year ) {
              return CalendarUtils.getCurrentMonth(true);
         }

         return 12;
    }


}
