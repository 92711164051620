import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { CompanyName } from '../../models/master/company/company-name.model';
import { AdminGuideApiEndpointsUtils } from '../../api/endpoints/admin-guide-api-endponints.utils';


@Injectable({
     providedIn: 'root'
})
export class AdminGuideService extends BaseService {


     getGuidesNames(providerId: number): Observable<Array<CompanyName>> {

          return super.sendGetRequestWithRefresh(AdminGuideApiEndpointsUtils.getAdminGuidesNamesEndpoint(providerId), AdminGuideApiEndpointsUtils.getAdminGuidesNamesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const guides: Array<CompanyName> = response.map((guide: any) => {
                         return new CompanyName(
                              guide.id,
                              guide.companyName
                         )
                    })

                    return guides;

               })
          )

     }

}
