import { TourResume } from '../../models/tour/tour-resume.model';
import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class ProviderGuideApiEndpointsUtils {

     public static getCreateGuideEndpoint(): string {
          return `provider/guidesmanagement/guides`;
     }

     public static getCreateGuideObject(
          name: string, email: string, languageId: number, cityId: number
     ): any {

          return {
            'name': name,
            'email': email,
            'languageId': languageId,
            'cityId': cityId
          }
     }

     public static getCreateGuideEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getGuidesEndpoint(page: number): string {
          return ApiUtils.prepareQueryRequest('provider/guidesmanagement/guides', { 'page': page });
     }

     public static getGuidesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getGuideDetailsEndpoint(guideId: number): string {
          return `provider/guidesmanagement/guides/${guideId}`;
     }

     public static getGuideDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUnregisterGuideEndpoint(guideId: number): string {
          return `provider/guidesmanagement/guides/${guideId}/unregister`;
     }

     public static getUnregisterGuideEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRegisterGuideEndpoint(guideId: number): string {
          return `provider/guidesmanagement/guides/${guideId}/register`;
     }

     public static getRegisterGuideEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUpdateGuideDetailsEndpoint(guideId: number): string {
          return `provider/guidesmanagement/guides/${guideId}/companydetails`;
     }

     public static getUpdateGuideDetailsObject(
          bussinesName: string,
          businessAddress: string,
          businessDocumentType: string,
          businessDocument: string,
          businessZeroVat: boolean,
          phone: string,
          country: number,
          city: number,
     ): any {

          return {
            'bussinesName': bussinesName,
            'businessAddress': businessAddress,
            'businessDocumentType': businessDocumentType,
            'businessDocument': businessDocument,
            'businessZeroVat': businessZeroVat,
            'phone': phone,
            'countryId': country,
            'cityId': city
          }
     }

     public static getUpdateGuideDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderGuidesNamesEndpoint(): string {
          return `provider/guidesmanagement/guides/names`;
     }

     public static getProviderGuidesNamesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderFindGuideBillingEndpoint(dateInit: string, dateEnd: string, guideId: number, keywords: string, page: number): string {
          return ApiUtils.prepareQueryRequest('provider/guidesmanagement/billing', { 'minDay': dateInit, 'maxDay': dateEnd, 'guideId': guideId, 'search': keywords, 'page': page });
     }

     public static getProviderFindGuideBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     public static getUpdateGuideManagedToursEndpoint(guideId: number): string {
          return `provider/guidesmanagement/guides/${guideId}/managedtours`;
     }

     public static getUpdateGuideManagedToursEndpointObject(tours: Array<TourResume>): any {

          const tourIds: Array<number> = new Array<number>();
          tours.map( (tourResume: TourResume) => {
               tourIds.push(tourResume.id);
          })

          return {
            'tourIds': tourIds
          }
     }

     public static getUpdateGuideManagedToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getUpdateGuideResellerToursEndpoint(guideId: number): string {
          return `provider/guidesmanagement/guides/${guideId}/resellertours`;
     }

     public static getUpdateGuideResellerToursEndpointObject(tours: Array<TourResume>): any {

          const tourIds: Array<number> = new Array<number>();
          tours.map( (tourResume: TourResume) => {
               tourIds.push(tourResume.id);
          })

          return {
            'tourIds': tourIds
          }
     }

     public static getUpdateGuideResellerToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getFindToursForResellingEndpoint(search: string, countryId: number, cityId: number, page: number): string {
          return ApiUtils.prepareQueryRequest('provider/guidesmanagement/tours/', { 'search': search, 'countryId': countryId, 'cityId': cityId, 'page': page });
     }

     public static getFindToursForResellingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

}