import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {NgxPaginationModule} from 'ngx-pagination';
import { SharedModule } from 'src/app/shared.module';
import { ProviderComponent } from './provider.component';
import { ProviderDashboardComponent } from './dashboard/dashboard.component';
import { PROVIDER_ROUTES } from './provider.routes';
import { ProviderProfileComponent } from './profile/profile.component';
import { CreateTourComponent } from './tour/create/create.component';
import { ListToursComponent } from './tour/list/list.component';
import { ProviderListBookingComponent } from './booking/list/list.component';
import { DetailBookingComponent } from './booking/detail/detail.component';
import { TourGalleryManagmentComponent } from './tour/gallery/gallery-managment.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YtPlayerAngularModule } from 'yt-player-angular';
import { ProviderTutorialComponent } from './tutorial/tutorial.component';
import { ProviderProfileSettingsComponent } from './profile/settings/settings.component';
import { ProviderListBillingComponent } from './billing/list/list.component';
import { ProviderListInvoicesComponent } from './billing/invoices/list/list.component';
import { ProviderInvoicdeDetailsComponent } from './billing/invoices/details/invoice-details.component';
import { CreateGuideComponent } from './guide/create/create.component';
import { ProviderGuideStateNamePipe } from '../../pipes/provider/provider-guide-name-state-name.pipe';
import { ProvideGuideStateClassPipe } from '../../pipes/provider/provider-guide-state-class.pipe';
import { ListGuidesComponent } from './guide/list/list.component';
import { GuidesTableComponent } from './guide/list/components/table/table.component';
import { GuidesCardsComponent } from './guide/list/components/cards/cards.component';
import { DetailGuideComponent } from './guide/detail/detail.component';
import { ProviderGuideListBillingComponent } from './guide/billing/list/list.component';
import { GuideDetailsToursManagementComponent } from './guide/detail/tours-management/tours-management.component';
import { GuideDetailsToursResellerComponent } from './guide/detail/tours-reseller/tours-reseller.component';


@NgModule({
  declarations: [
    ProviderComponent,
    ProviderDashboardComponent,
    ProviderProfileComponent,
    CreateTourComponent,
    ListToursComponent,
    ProviderListBookingComponent,
    DetailBookingComponent,
    TourGalleryManagmentComponent,
    ProviderTutorialComponent,
    ProviderProfileSettingsComponent,
    ProviderListInvoicesComponent,
    ProviderListBillingComponent,
    ProviderInvoicdeDetailsComponent,
    CreateGuideComponent,
    ProviderGuideStateNamePipe,
    ProvideGuideStateClassPipe,
    ListGuidesComponent,
    GuidesTableComponent,
    GuidesCardsComponent,
    DetailGuideComponent,
    ProviderGuideListBillingComponent,
    GuideDetailsToursManagementComponent,
    GuideDetailsToursResellerComponent
  ],
  exports: [
  ],
  imports: [
    PROVIDER_ROUTES,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxPaginationModule,
    NgbModule,
    YtPlayerAngularModule
  ]
})
export class ProviderModule { }
