import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { BookingGuideConfirmationState } from '../../models/book/common/booking-guide-confirmation-state.constants';
import { BookingUtils } from '../../models/book/common/booking.utils';

export class GuideApiEndpointsUtils {


     public static getGuideMeEndpoint(): string {
          return `guide/guides`;
     }

     public static getGuideMeEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getGuideProfileUpdateDetailsEndpoint(): string {
          return 'guide/guides/companydetails';
     }

     public static getGuideProfileUpdateDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getGuideProfileUpdateDetailsObject(
          companyName: string,
          phone: string,
          country: number,
          city: number
     ): any {

          return {
            'companyName': companyName,
            'phone': phone,
            'countryId': country,
            'cityId': city
          }
     }


     public static getGuideProfileUpdateSettingsEndpoint(): string {
          return 'guide/settings';
     }

     public static getGuideProfileUpdateSettingsObject( languageCode: string, paymentCurrency: string, iban: string): any {

          return {
               'languageCode': languageCode,
               'paymentCurrency': paymentCurrency,
               'iban': iban
          }
     }

     public static getGuideProfileUpdateSettingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getGuideFindBillingEndpoint(dateInit: string, dateEnd: string, keywords: string, page: number): string {
          return ApiUtils.prepareQueryRequest('guide/billing', { 'minDay': dateInit, 'maxDay': dateEnd, 'search': keywords, 'page': page });
     }

     public static getGuideFindBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getGuideFindBookEventsEndpoint(day: string, page: number): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return ApiUtils.prepareQueryRequest('guide/events/bookings', { 'day': dayFormatted, 'page': page });
     }

     public static getGuideFindBookEventsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getGuideFindBookingsEndpoint(tourId: number, date: string, hour: string, keywords: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return ApiUtils.prepareQueryRequest(`guide/events/bookings/${tourId}/${dayFormatted}/${hour}`, { 'search': keywords });
     }

     public static getGuideFindBookingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getGuideUpdateBookingsEndpoint(tourId: number, date: string, hour: string, bookId: number): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `guide/events/bookings/${tourId}/${dayFormatted}/${hour}/${bookId}`;
     }

     public static getGuideUpdateBookingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getGuideUpdateBookingsObject(isChildren: boolean, isAdult: boolean, orderFareId: number, shows: number): any {
          return {
               "isChildren": isChildren,
               "isAdult": isAdult,
               "orderFareId": orderFareId,
               "shows": shows
          };
     }




     public static getGuideUpdateBookingsAllFaresEndpoint(tourId: number, date: string, hour: string, bookId: number, ): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `guide/events/bookings/confirm/${tourId}/${dayFormatted}/${hour}/${bookId}`;
     }

     public static getGuideUpdateBookingsAllFaresEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getGuideUpdateBookingsAllFaresObject(bookingGuideConfirmationState: BookingGuideConfirmationState): any {
          const guideConfirmationState: number = BookingUtils.getGuideConfirmationStateApiFromBookingGuideConfirmationState(bookingGuideConfirmationState);
          return {
               "guideConfirmationState": guideConfirmationState
          };
     }

}