import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { ProviderGuideApiEndpointsUtils } from '../../api/endpoints/provider-guide-api-endponints.utils';
import { GuideResume } from '../../models/guide/guide-resume.model';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { GuideUtils } from '../../models/guide/common/guide.utils';
import { Guide } from '../../models/guide/guide.model';
import { CompanyName } from '../../models/master/company/company-name.model';
import { TourResume } from '../../models/tour/tour-resume.model';
import { TourUtils } from '../../models/tour/common/tour.utils';


@Injectable({
     providedIn: 'root'
})
export class ProviderGuideService extends BaseService {


     createGuide(name: string, email: string, languageId: number, cityId: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(ProviderGuideApiEndpointsUtils.getCreateGuideEndpoint(), ProviderGuideApiEndpointsUtils.getCreateGuideObject(name, email, languageId, cityId), ProviderGuideApiEndpointsUtils.getCreateGuideEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     findGuides(page: number): Observable<ChunkGeneric<GuideResume>> {

          return super.sendGetRequestWithRefresh(ProviderGuideApiEndpointsUtils.getGuidesEndpoint(page), ProviderGuideApiEndpointsUtils.getGuidesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const pagination: Pagination = new Pagination(response.total, response.currentPage, response.chunkSize);
                    const guides: Array<GuideResume> = response.items.map((guideResumeApi: any) => {
                         return GuideUtils.getGuideResumeFromApi(guideResumeApi);
                    })

                    return new ChunkGeneric<GuideResume>(guides, pagination);

               })
          )

     }


     getGuideDetalis(localeI18N: string, guideId: number): Observable<Guide> {

          return super.sendGetRequestWithRefresh(ProviderGuideApiEndpointsUtils.getGuideDetailsEndpoint(guideId), ProviderGuideApiEndpointsUtils.getGuideDetailsEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    return GuideUtils.getGuideFromApi(response, localeI18N);

               })
          )

     }


     unregisterGuide(guideId: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(ProviderGuideApiEndpointsUtils.getUnregisterGuideEndpoint(guideId), {}, ProviderGuideApiEndpointsUtils.getUnregisterGuideEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }

     registerGuide(guideId: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(ProviderGuideApiEndpointsUtils.getRegisterGuideEndpoint(guideId), {}, ProviderGuideApiEndpointsUtils.getRegisterGuideEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }

     updateGuideDetails(guideId: number, bussinesName: string, businessAddress: string, businessDocumentType: string, businessDocument: string, businessZeroVat: boolean, phone: string, country: number, city: number): Observable<boolean> {
          return super.sendPutRequestWithRefresh(ProviderGuideApiEndpointsUtils.getUpdateGuideDetailsEndpoint(guideId), ProviderGuideApiEndpointsUtils.getUpdateGuideDetailsObject(bussinesName, businessAddress, businessDocumentType, businessDocument, businessZeroVat, phone, country, city), ProviderGuideApiEndpointsUtils.getUpdateGuideDetailsEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     updateGuideManagedTours(guideId: number, tours: Array<TourResume>): Observable<boolean> {
          return super.sendPutRequestWithRefresh(ProviderGuideApiEndpointsUtils.getUpdateGuideManagedToursEndpoint(guideId), ProviderGuideApiEndpointsUtils.getUpdateGuideManagedToursEndpointObject(tours), ProviderGuideApiEndpointsUtils.getUpdateGuideManagedToursEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     updateGuideResellerTours(guideId: number, tours: Array<TourResume>): Observable<boolean> {
          return super.sendPutRequestWithRefresh(ProviderGuideApiEndpointsUtils.getUpdateGuideResellerToursEndpoint(guideId), ProviderGuideApiEndpointsUtils.getUpdateGuideResellerToursEndpointObject(tours), ProviderGuideApiEndpointsUtils.getUpdateGuideResellerToursEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


    findToursForReselling(localeI18N: string, search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

      return super.sendGetRequestWithRefresh(ProviderGuideApiEndpointsUtils.getFindToursForResellingEndpoint(search, countryId, cityId, page), ProviderGuideApiEndpointsUtils.getFindToursForResellingEndpointAcceptVersion())
        .pipe(
          map((response: any) => {

            const toursResume = new Array<TourResume>();

            response.items.map((tourBasicApi: any) => {

              const tourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);
              toursResume.push(tourResume);
            })

            return new ChunkGeneric<TourResume>(toursResume, new Pagination(response.total, response.currentPage, response.chunkSize));

          })
        )
    }


     getGuidesNames(): Observable<Array<CompanyName>> {

          return super.sendGetRequestWithRefresh(ProviderGuideApiEndpointsUtils.getProviderGuidesNamesEndpoint(), ProviderGuideApiEndpointsUtils.getProviderGuidesNamesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const guides: Array<CompanyName> = response.map((guide: any) => {
                         return new CompanyName(
                              guide.id,
                              guide.companyName
                         )
                    })

                    return guides;

               })
          )
     }

}
