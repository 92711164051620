import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class GuideTourApiEndpointsUtils {

     public static getFindToursForResellingEndpoint(search: string, countryId: number, cityId: number, page: number): string {
          return ApiUtils.prepareQueryRequest('guide/tours/reseller', { 'search': search, 'countryId': countryId, 'cityId': cityId, 'page': page });
     }

     public static getFindToursForResellingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

}