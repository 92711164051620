export class NavigationConstants {

     //PARAMETERS
     public static NAVIGATION_ADMIN_PROVIDER_DETAIL_ID: string  = 'id';
     public static NAVIGATION_ADMIN_TOUR_UPDATE_ID: string  = 'id';
     public static NAVIGATION_ADMIN_TOUR_GALLLERY_MANAGMENT_ID: string  = 'id';
     public static NAVIGATION_ADMIN_BOOK_EVENT_TOUR_ID: string  = 'id';
     public static NAVIGATION_ADMIN_INVOICE_ID: string  = 'id';
     public static NAVIGATION_ADMIN_BOOK_EVENT_DATE: string  = 'date';
     public static NAVIGATION_ADMIN_BOOK_EVENT_HOUR: string  = 'hour';
     public static NAVIGATION_LOGIN_REDIRECT_PARAMETER: string  = 'redirect';
     public static NAVIGATION_ANCHOR_PARAMETER: string  = 'anchor';
     public static NAVIGATION_ADMIN_AFFILIATE_DETAIL_ID: string  = 'id';
     public static NAVIGATION_PROVIDER_GUIDE_DETAIL_ID: string  = 'id';
     public static NAVIGATION_GUIDE_BOOK_EVENT_TOUR_ID: string  = 'id';





     //ERROR
     public static NAVIGATION_FORBIDDEN: string  = 'forbidden';


     // PUBLIC
     public static NAVIGATION_HOME: string  = '/';
     public static NAVIGATION_HOME_ANCHOR_HOME: string  = 'home';
     public static NAVIGATION_HOME_ANCHOR_FEATURES: string  = 'features';
     public static NAVIGATION_HOME_ANCHOR_ADVANTAGES: string  = 'advantages';
     public static NAVIGATION_HOME_ANCHOR_CONTACT: string  = 'contact';
     public static NAVIGATION_TERMS: string  = 'terms';
     public static NAVIGATION_REVIEW: string  = 'bookingreview';
     public static NAVIGATION_REVIEW_THANKS: string  = 'bookingreview/thanks';
     public static NAVIGATION_BOOKIN_CANCELATION: string  = 'bookingcancelation';
     public static NAVIGATION_BOOKIN_PAYMENT: string  = 'bookingpayment';

     public static NAVIGATION_PLANS: string  = 'plans';
     public static NAVIGATION_PLANS_ANCHOR_FEATURED_PLANS: string  = 'features-plans';




     //AUTH
     public static NAVIGATION_LOGIN: string  = 'login';
     public static NAVIGATION_REMEMBER_PASSWORD: string  = 'rememberpassword';
     public static NAVIGATION_REMEMBER_PASSWORD_CONFIRMATION: string  = 'changepassword';

     public static NAVIGATION_PROVIDER_REGISTER: string  = 'singup';


     public static NAVIGATION_AFFILIATE_REGISTER: string  = 'singupaffiliate';



     //ADMIN
     public static NAVIGATION_USER_ZONE: string  = 'userzone';
     public static NAVIGATION_ADMIN: string  = 'admin';
     public static NAVIGATION_ADMIN_DASHBOARD: string  = 'dashboard';
     public static NAVIGATION_ADMIN_CREATE_PROVIDER: string  = 'createprovider';
     public static NAVIGATION_ADMIN_LIST_PROVIDERS: string  = 'listproviders';
     public static NAVIGATION_ADMIN_PROVIDER_DETAIL_NO_PARAMETER: string  = 'provider';
     public static NAVIGATION_ADMIN_PROVIDER_DETAIL: string  = NavigationConstants.NAVIGATION_ADMIN_PROVIDER_DETAIL_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_PROVIDER_DETAIL_ID;
     public static NAVIGATION_ADMIN_PROFILE: string  = NavigationConstants.NAVIGATION_ADMIN + 'profile';
     public static NAVIGATION_ADMIN_LIST_TOURS: string  = 'listtours';
     public static NAVIGATION_ADMIN_REVIEW: string  = 'reviews';
     public static NAVIGATION_ADMIN_BILLING: string  = 'billing';
     public static NAVIGATION_ADMIN_INVOICES: string  = 'invoices';
     public static NAVIGATION_ADMIN_INVOICES_DETAILS_NO_PARAMETER: string = NavigationConstants.NAVIGATION_ADMIN_INVOICES;
     public static NAVIGATION_ADMIN_INVOICES_DETAILS: string = NavigationConstants.NAVIGATION_ADMIN_INVOICES_DETAILS_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_INVOICE_ID;

     public static NAVIGATION_ADMIN_LIST_BOOKING: string  = 'listbooking';
     public static NAVIGATION_ADMIN_DETAIL_BOOKING_NO_PARAMETER: string  = 'detailbooking';
     public static NAVIGATION_ADMIN_DETAIL_BOOKING: string  = NavigationConstants.NAVIGATION_ADMIN_DETAIL_BOOKING_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_TOUR_ID;


     public static NAVIGATION_ADMIN_CREATE_TOUR: string  = 'createtour';

     public static NAVIGATION_ADMIN_UPDATE_TOUR_NO_PARAMETER: string  = 'updatetour';
     public static NAVIGATION_ADMIN_UPDATE_TOUR: string  = NavigationConstants.NAVIGATION_ADMIN_UPDATE_TOUR_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_TOUR_UPDATE_ID;

     public static NAVIGATION_ADMIN_TOUR_GALLERY_MANAGMENT_NO_PARAMETER: string  = 'tourgallerymanagment';
     public static NAVIGATION_ADMIN_TOUR_GALLERY_MANAGMENT: string  = NavigationConstants.NAVIGATION_ADMIN_TOUR_GALLERY_MANAGMENT_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_TOUR_GALLLERY_MANAGMENT_ID;

     public static NAVIGATION_ADMIN_CREATE_AFFILIATE: string  = 'createaffiliate';
     public static NAVIGATION_ADMIN_LIST_AFFILIATES: string  = 'listaffiliates';
     public static NAVIGATION_ADMIN_AFFILIATE_DETAIL_NO_PARAMETER: string  = 'affiliate';
     public static NAVIGATION_ADMIN_AFFILIATE_DETAIL: string  = NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_DETAIL_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_DETAIL_ID;
     public static NAVIGATION_ADMIN_AFFILIATE_BILLING: string  = 'affiliatebilling';
     public static NAVIGATION_ADMIN_AFFILIATE_INVOICES: string  = 'affiliateinvoices';
     public static NAVIGATION_ADMIN_AFFILIATE_INVOICES_DETAILS_NO_PARAMETER: string = NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_INVOICES;
     public static NAVIGATION_ADMIN_AFFILIATE_INVOICES_DETAILS: string = NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_INVOICES_DETAILS_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_INVOICE_ID;

     public static NAVIGATION_ADMIN_GUIDE_BILLING: string  = 'guidebilling';

     public static getNavigationUserZone(): string {
          return NavigationConstants.NAVIGATION_USER_ZONE;
     }

     public static getNavigationLogin(redirectUrl: string = null): string {
          let redirectparameter = "";
          if ( redirectUrl ) {
               redirectparameter = "?" + NavigationConstants.NAVIGATION_LOGIN_REDIRECT_PARAMETER + "=" + encodeURIComponent(redirectUrl);
          }
          return NavigationConstants.NAVIGATION_LOGIN + redirectparameter;
     }

     public static getNavigationRememberPassword(): string {
          return NavigationConstants.NAVIGATION_REMEMBER_PASSWORD;
     }

     public static getNavigationForbidden(): string {
          return NavigationConstants.NAVIGATION_FORBIDDEN;
     }

     public static getNavigationAdminDashboard(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_DASHBOARD;
     }

     public static getNavigationAdminCreateProvider(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_CREATE_PROVIDER;
     }

     public static getNavigationAdminListProviders(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_LIST_PROVIDERS;
     }

     public static getNavigationAdminProviderDetail(providerId: number): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_PROVIDER_DETAIL_NO_PARAMETER + '/' + providerId;
     }

     public static getNavigationAdminCreateAffiliate(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_CREATE_AFFILIATE;
     }

     public static getNavigationAdminListAffiliates(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_LIST_AFFILIATES;
     }

     public static getNavigationAdminAffiliateDetail(affiliateId: number): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_DETAIL_NO_PARAMETER + '/' + affiliateId;
     }

     public static getNavigationAdminAffiliateBilling(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_BILLING;
     }

     public static getNavigationAdminAffiliateInvoices(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_INVOICES;
     }

     public static getNavigationAdminProfile(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_PROFILE;
     }

     public static getNavigationAdminListTours(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_LIST_TOURS;
     }

     public static getNavigationAdminReviews(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_REVIEW;
     }

     public static getNavigationAdminBilling(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_BILLING;
     }

     public static getNavigationAdminInvoices(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_INVOICES;
     }

     public static getNavigationAdminInvoiceDetails(invoiceId: number): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_INVOICES_DETAILS_NO_PARAMETER + '/' + invoiceId;
     }

     public static getNavigationAdminAffiliateInvoiceDetails(invoiceId: number): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_INVOICES_DETAILS_NO_PARAMETER + '/' + invoiceId;
     }

     public static getNavigationAdminCreateTour(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_CREATE_TOUR;
     }

     public static getNavigationAdminUpdateTour(tourId: number): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_UPDATE_TOUR_NO_PARAMETER + '/' + tourId;
     }

     public static getNavigationAdminTourGalleryManagment(tourId: number): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_TOUR_GALLERY_MANAGMENT_NO_PARAMETER + '/' + tourId;
     }

     public static getNavigationAdminListBooking(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_LIST_BOOKING;
     }

     public static getNavigationAdminDetailBooking(tourId: number, date: string, hour: string): string {
          let targetUrl = NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_DETAIL_BOOKING_NO_PARAMETER + '/' + tourId;
          targetUrl += "?" + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_DATE + "=" + date + "&" + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_HOUR + "=" + hour;

          return targetUrl;
     }

     public static getNavigationAdminGuideBilling(): string {
          return NavigationConstants.NAVIGATION_ADMIN + '/' + NavigationConstants.NAVIGATION_ADMIN_GUIDE_BILLING;
     }





     //PROVIDERS
     public static NAVIGATION_PROVIDER: string  = 'provider';
     public static NAVIGATION_PROVIDER_DASHBOARD: string  = 'dashboard';
     public static NAVIGATION_PROVIDER_PROFILE: string  = 'profile';
     public static NAVIGATION_PROVIDER_LIST_TOURS: string  = 'listtours';
     public static NAVIGATION_PROVIDER_CREATE_TOUR: string  = 'createtour';
     public static NAVIGATION_PROVIDER_UPDATE_TOUR_NO_PARAMETER: string  = 'updatetour';
     public static NAVIGATION_PROVIDER_UPDATE_TOUR: string  = NavigationConstants.NAVIGATION_PROVIDER_UPDATE_TOUR_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_TOUR_UPDATE_ID;

     public static NAVIGATION_PROVIDER_TOUR_GALLERY_MANAGMENT_NO_PARAMETER: string  = 'tourgallerymanagment';
     public static NAVIGATION_PROVIDER_TOUR_GALLERY_MANAGMENT: string  = NavigationConstants.NAVIGATION_PROVIDER_TOUR_GALLERY_MANAGMENT_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_TOUR_GALLLERY_MANAGMENT_ID;

     public static NAVIGATION_PROVIDER_LIST_BOOKING: string  = 'listbooking';
     public static NAVIGATION_PROVIDER_DETAIL_BOOKING_NO_PARAMETER: string  = 'detailbooking';
     public static NAVIGATION_PROVIDER_DETAIL_BOOKING: string  = NavigationConstants.NAVIGATION_PROVIDER_DETAIL_BOOKING_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_TOUR_ID;

     public static NAVIGATION_PROVIDER_BILLING: string  = 'billing';
     public static NAVIGATION_PROVIDER_INVOICES: string  = 'invoices';
     public static NAVIGATION_PROVIDER_INVOICE_DETAILS_NO_PARAMETER: string = NavigationConstants.NAVIGATION_PROVIDER_INVOICES;
     public static NAVIGATION_PROVIDER_INVOICE_DETAILS: string = NavigationConstants.NAVIGATION_PROVIDER_INVOICE_DETAILS_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_INVOICE_ID;

     public static NAVIGATION_PROVIDER_TUTORIAL: string  = 'tutorial';

     public static NAVIGATION_PROVIDER_CREATE_GUIDE: string  = 'createguide';
     public static NAVIGATION_PROVIDER_LIST_GUIDES: string  = 'listguides';
     public static NAVIGATION_PROVIDER_GUIDE_DETAIL_NO_PARAMETER: string  = 'guide';
     public static NAVIGATION_PROVIDER_GUIDE_DETAIL: string  = NavigationConstants.NAVIGATION_PROVIDER_GUIDE_DETAIL_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_PROVIDER_GUIDE_DETAIL_ID;
     public static NAVIGATION_PROVIDER_GUIDE_BILLING: string  = 'guidebilling';

     public static getNavigationProviderDashboard(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_DASHBOARD;
     }

     public static getNavigationProviderProfile(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_PROFILE;
     }

     public static getNavigationProviderListTours(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_LIST_TOURS;
     }

     public static getNavigationProviderCreateTour(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_CREATE_TOUR;
     }

     public static getNavigationProviderUpdateTour(tourId: number): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_UPDATE_TOUR_NO_PARAMETER + '/' + tourId;
     }

     public static getNavigationProviderTourGalleryManagment(tourId: number): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_TOUR_GALLERY_MANAGMENT_NO_PARAMETER + '/' + tourId;
     }

     public static getNavigationProviderListBooking(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_LIST_BOOKING;
     }

     public static getNavigationProviderDetailBooking(tourId: number, date: string, hour: string): string {
          let targetUrl = NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_DETAIL_BOOKING_NO_PARAMETER + '/' + tourId;
          targetUrl += "?" + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_DATE + "=" + date + "&" + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_HOUR + "=" + hour;

          return targetUrl;
     }

     public static getNavigationProviderTutorial(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_TUTORIAL;
     }


     public static getNavigationProviderBilling(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_BILLING;
     }

     public static getNavigationProviderInvoices(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_INVOICES;
     }

     public static getNavigationProviderInvoiceDetails(invoiceId: number): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_INVOICE_DETAILS_NO_PARAMETER + '/' + invoiceId;
     }


     public static getNavigationProviderListGuides(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_LIST_GUIDES;
     }

     public static getNavigationProviderCreateGuide(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_CREATE_GUIDE;
     }

     public static getNavigationProviderGuideDetail(guideId: number): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_GUIDE_DETAIL_NO_PARAMETER + '/' + guideId;
     }

     public static getNavigationProviderGuideBilling(): string {
          return NavigationConstants.NAVIGATION_PROVIDER + '/' + NavigationConstants.NAVIGATION_PROVIDER_GUIDE_BILLING;
     }





     //public
     public static getNavigationHome(anchor: string = null): string {
          return NavigationConstants.NAVIGATION_HOME;
     }

     public static getNavigationPlansLanding(): string {
          return NavigationConstants.NAVIGATION_PLANS;
     }

     public static getNavigationTerms(): string {
          return NavigationConstants.NAVIGATION_TERMS;
     }

     public static getNavigationReviewThanks(): string {
          return NavigationConstants.NAVIGATION_REVIEW_THANKS;
     }


     public static redirectUrl(navigation: string): string {
          return '/' + navigation;
     }





     //AFFILIATES
     public static NAVIGATION_AFFILIATE: string  = 'affiliate';
     public static NAVIGATION_AFFILIATE_DASHBOARD: string  = 'dashboard';
     public static NAVIGATION_AFFILIATE_PROFILE: string  = 'profile';
     public static NAVIGATION_AFFILIATE_LIST_TOURS: string  = 'listtours';
     public static NAVIGATION_AFFILIATE_BILLING: string  = 'billing';
     public static NAVIGATION_AFFILIATE_INVOICES: string  = 'invoices';
     public static NAVIGATION_AFFILIATE_INVOICE_DETAILS_NO_PARAMETER: string = NavigationConstants.NAVIGATION_AFFILIATE_INVOICES;
     public static NAVIGATION_AFFILIATE_INVOICE_DETAILS: string = NavigationConstants.NAVIGATION_AFFILIATE_INVOICE_DETAILS_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_ADMIN_INVOICE_ID;


     public static getNavigationAffiliateProfile(): string {
          return NavigationConstants.NAVIGATION_AFFILIATE + '/' + NavigationConstants.NAVIGATION_AFFILIATE_PROFILE;
     }

     public static getNavigationAffiliateListTours(): string {
          return NavigationConstants.NAVIGATION_AFFILIATE + '/' + NavigationConstants.NAVIGATION_AFFILIATE_LIST_TOURS;
     }

     public static getNavigationAffiliateDashboard(): string {
          return NavigationConstants.NAVIGATION_AFFILIATE + '/' + NavigationConstants.NAVIGATION_AFFILIATE_DASHBOARD;
     }

     public static getNavigationAffiliateBilling(): string {
          return NavigationConstants.NAVIGATION_AFFILIATE + '/' + NavigationConstants.NAVIGATION_AFFILIATE_BILLING;
     }

     public static getNavigationAffiliateInvoices(): string {
          return NavigationConstants.NAVIGATION_AFFILIATE + '/' + NavigationConstants.NAVIGATION_AFFILIATE_INVOICES;
     }

     public static getNavigationAffiliateInvoiceDetails(invoiceId: number): string {
          return NavigationConstants.NAVIGATION_AFFILIATE + '/' + NavigationConstants.NAVIGATION_AFFILIATE_INVOICE_DETAILS_NO_PARAMETER + '/' + invoiceId;
     }



     //GUIDES
     public static NAVIGATION_GUIDE: string  = 'guide';
     public static NAVIGATION_GUIDE_PROFILE: string  = 'profile';
     public static NAVIGATION_GUIDE_LIST_BOOKING: string  = 'listbooking';
     public static NAVIGATION_GUIDE_DETAIL_BOOKING_NO_PARAMETER: string  = 'detailbooking';
     public static NAVIGATION_GUIDE_DETAIL_BOOKING: string  = NavigationConstants.NAVIGATION_GUIDE_DETAIL_BOOKING_NO_PARAMETER + '/:' + NavigationConstants.NAVIGATION_GUIDE_BOOK_EVENT_TOUR_ID;
     public static NAVIGATION_GUIDE_BILLING: string  = 'billing';

     public static getNavigationGuideProfile(): string {
          return NavigationConstants.NAVIGATION_GUIDE + '/' + NavigationConstants.NAVIGATION_GUIDE_PROFILE;
     }

     public static getNavigationGuideListBookings(): string {
          return NavigationConstants.NAVIGATION_GUIDE + '/' + NavigationConstants.NAVIGATION_GUIDE_LIST_BOOKING;
     }

     public static getNavigationGuideDetailBooking(tourId: number, date: string, hour: string): string {
          let targetUrl = NavigationConstants.NAVIGATION_GUIDE + '/' + NavigationConstants.NAVIGATION_GUIDE_DETAIL_BOOKING_NO_PARAMETER + '/' + tourId;
          targetUrl += "?" + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_DATE + "=" + date + "&" + NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_HOUR + "=" + hour;

          return targetUrl;
     }

     public static getNavigationGuideBilling(): string {
          return NavigationConstants.NAVIGATION_GUIDE + '/' + NavigationConstants.NAVIGATION_GUIDE_BILLING;
     }

}
