import { TourFormView } from 'src/app/domain/views/provider/tour/tour-form.view';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';
import { BookingUtils } from '../../models/master/booking/booking.utils';
import { ApiBuildUtils } from '../api.build.utils';
import { CancelationReason } from '../../models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { PaymentPlatform } from '../../models/master/payment-platform/payment-platform.constants';
import { PaymentPlatformUtils } from '../../models/master/payment-platform/payment-platform.utils';

export class ProviderApiEndpointsUtils {


     public static getProviderCreateTourEndpoint(): string {
          return `provider/tours`;
     }

     public static getProviderCreateTourEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderUpdateTourEndpoint(tourId: number): string {
          return `provider/tours/${tourId}`;
     }

     public static getProviderUpdateTourEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderTourDetailsEndpoint(tourId: number): string {
          return `provider/tours/${tourId}`;
     }

     public static getProviderTourDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderCreateTourObject(tourForm: TourFormView): any {
          return ApiBuildUtils.buildRequestTourObject(tourForm);
     }

     public static getProviderCreateTourI18NObject(tourForm: TourFormView): any {
          return ApiBuildUtils.buildRequestTourI18NObject(tourForm);
     }






     public static getProviderFindBookEventsEndpoint(day: string, countryId: number, cityId: number, tourId: number, page: number): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return ApiUtils.prepareQueryRequest('provider/events/bookings', { 'day': dayFormatted, 'countryId': countryId, 'cityId': cityId, 'tourId': tourId, 'page': page });
     }

     public static getProviderFindBookEventsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderFindBookingsEndpoint(tourId: number, date: string, hour: string, keywords: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return ApiUtils.prepareQueryRequest(`provider/events/bookings/${tourId}/${dayFormatted}/${hour}`, { 'search': keywords });
     }

     public static getProviderFindBookingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderUpdateBookingsEndpoint(tourId: number, date: string, hour: string, bookId: number): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `provider/events/bookings/${tourId}/${dayFormatted}/${hour}/${bookId}`;
     }

     public static getProviderUpdateBookingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderUpdateBookingsObject(isChildren: boolean, isAdult: boolean, orderFareId: number, shows: number): any {
          return {
               "isChildren": isChildren,
               "isAdult": isAdult,
               "orderFareId": orderFareId,
               "shows": shows
          };
     }




     public static getProviderUpdateBookingsContactDetailsEndpoint(bookId: number): string {
          return `provider/events/bookings/${bookId}`;
     }


     public static getProviderUpdateBookingsContactDetailsObject(email: string, name: string, surname: string, phone: string): any {
          return {
               "email": email,
               "name": name,
               "surname": surname,
               "phone": phone
          };
     }

     public static getProviderUpdateBookingsContactDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderResendBookConfirmationEmailEndpoint(bookId: number): string {
          return `provider/events/bookings/${bookId}/remember`;
     }

     public static getProviderResendBookConfirmationEmailEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     public static getProviderCancelBookingEndpoint(bookId: number): string {
          return `provider/events/bookings/${bookId}/cancel`;
     }

     public static getProviderCancelBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderCancelBookingEndpointObject(message: string, cancelationReason: CancelationReason): any {
          return {
               "type": BookingUtils.getApyTypeFromCancelationReason(cancelationReason),
               "reason": message
          };
     }




     public static getBlockEventBookingEndpoint(tourId: number, date: string, hour: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `provider/events/${tourId}/${dayFormatted}/${hour}/block`;
     }

     public static getBlockEventBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getBlockEventBookingEndpointObject(block: boolean): any {
          return {
               "block": block
          };
     }




     public static getUpdateEventMaxBookingEndpoint(tourId: number, date: string, hour: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `provider/events/${tourId}/${dayFormatted}/${hour}`;
     }

     public static getUpdateEventMaxBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getUpdateEventMaxBookingObject(maxBookings: number): any {
          return {
               "maxPeople": maxBookings
          };
     }



     public static getProviderFindToursEndpoint(search: string, countryId: number, cityId: number, page: number): string {
          return ApiUtils.prepareQueryRequest('provider/tours/', { 'search': search, 'countryId': countryId, 'cityId': cityId, 'page': page });
     }

     public static getProviderFindToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderActivateToursEndpoint(tourId: number): string {
          return `provider/tours/${tourId}/activate`;
     }

     public static getProviderActivateToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderDeactivateToursEndpoint(tourId: number): string {
          return `provider/tours/${tourId}/deactivate`;
     }

     public static getProviderDeactivateToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderUploadImageTourEndpoint(tourId: number): string {
          return `provider/tours/${tourId}/images`;
     }

     public static getProviderUploadImageTourEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderTourImagesEndpoint(tourId: number): string {
          return `provider/tours/${tourId}/images`;
     }

     public static getProviderTourImagesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderTourDeleteImageEndpoint(tourId: number, imageId: number): string {
          return `provider/tours/${tourId}/images/${imageId}`;
     }

     public static getProviderTourDeleteImageEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getProviderTourUpdateBidEndpoint(tourId: number): string {
          return `provider/tours/${tourId}/bid`;
     }

     public static getProviderTourUpdateBidEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderTourUpdateBidEndpointObject(bid: number): any {
          return {
               "bid": bid
          };
     }



     public static getProviderFindInvoicesEndpoint(page: number): string {
          return ApiUtils.prepareQueryRequest('provider/billing/invoices', { 'page': page });
     }

     public static getProviderFindInvoicesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderFindNextBillingEndpoint(): string {
          return 'provider/billing/next';
     }

     public static getProviderFindNextBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getProviderFindBillingEndpoint(dateInit: string, dateEnd: string, keywords: string, page: number): string {
          return ApiUtils.prepareQueryRequest('provider/billing', { 'minDay': dateInit, 'maxDay': dateEnd, 'search': keywords, 'page': page });
     }

     public static getProviderFindBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getProviderPrepareInvoicePaymentEndpoint(invoiceId: number): string {
          return `provider/billing/invoices/${invoiceId}/payment`;
     }

     public static getProviderPrepareInvoicePaymentEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getProviderFindInvoiceEndpoint(invoiceId: number): string {
          return `provider/billing/invoices/${invoiceId}`;
     }

     public static getProviderFindInvoiceEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getProviderInvoiceDownloadEndpoint(invoiceId: number): string {
          return `provider/billing/invoices/${invoiceId}`;
     }

     public static getProviderInvoiceDownloadEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1_PDF;
     }


     public static getProviderProfileUpdateSettingsEndpoint(): string {
          return 'provider/settings';
     }

     public static getProviderProfileUpdateSettingsObject(
          languageCode: string, bookingNotificationEmailActive: boolean, bookingNotificationEmail: string,
          bookingNotificationEmail2: string, paymentPlatform: PaymentPlatform, paymentCurrency: string, iban: string
     ): any {

          return {
               'languageCode': languageCode,
               'bookingNotificationEmailActive': bookingNotificationEmailActive,
               'bookingNotificationEmail': bookingNotificationEmail,
               'bookingNotificationEmail2': bookingNotificationEmail2,
               'paymentPlatform': PaymentPlatformUtils.getApiPaymentPlatformFromPaymentPlatform(paymentPlatform),
               'paymentCurrency': paymentCurrency,
               'iban': iban
          }
     }

     public static getProviderProfileUpdateSettingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderMeEndpoint(): string {
          return `provider/providers`;
     }

     public static getProviderMeEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderProfileUpdateDetailsEndpoint(): string {
          return 'provider/providers/companydetails';
     }

     public static getProviderProfileUpdateDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderProfileUpdatePaymentEndpoint(): string {
          return `provider/providers/billingdetails`;
     }

     public static getProviderProfileUpdatePaymentEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getProviderProfileUpdatePaymentObject(
          businessName: string, businessDocumentType: string, businessDocument: string,
          businessAddress: string, businessZeroVat: boolean
     ): any {

          return {
               'businessName': businessName,
               'businessDocumentType': businessDocumentType,
               'businessDocument': businessDocument,
               'businessAddress': businessAddress,
               'businessZeroVat': businessZeroVat
          }
     }

     public static getProviderUploadLogoEndpoint(): string {
          return `provider/providers/images`;
     }

     public static getProviderUploadLogoEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderDeleteLogoEndpoint(imageId: number): string {
          return `provider/providers/images/${imageId}`;
     }

     public static getProviderDeleteLogoEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getProviderRequestUnregisterEndpoint(): string {
          return `provider/providers/requestunregister`;
     }

     public static getProviderRequestUnregisterEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderProfileCreateStripeAccountEndpoint(): string {
          return 'provider/providers/stripe/account';
     }

     public static getProviderProfileCreateStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderProfileConnectStripeAccountEndpoint(): string {
          return 'provider/providers/stripe/link';
     }

     public static getProviderProfileConnectStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderProfileRemoveStripeAccountEndpoint(): string {
          return 'provider/providers/stripe/account';
     }

     public static getProviderProfileRemoveStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderProfileUpdateStripeAccountEndpoint(): string {
          return 'provider/providers/stripe/account';
     }

     public static getProviderProfileUpdateStripeAccountObject(stripeAccountId: string): any {
          return {
               'stripeAccountId': stripeAccountId
          }
     }

     public static getProviderProfileUpdateStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


}


