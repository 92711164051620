import { BookingState } from '../book/common/booking-state.constants';
import { BillingDetails } from './billing-details.model';
import { CustomerDetails } from './customer-details.model';
import { InvoiceDetails } from './invoice-details.model';

export class BookingBilling {

  private _bookingId: number;
  private _tourId: number;
  private _tourNameTranslated: string;
  private _tourDate: string;
  private _tourHour: string;
  private _invoiceDetails: InvoiceDetails;
  private _billingDetails: BillingDetails;
  private _customerDetails: CustomerDetails;
  private _bookingState: BookingState;

  constructor(
    bookingId: number,
    tourId: number,
    tourNameTranslated: string,
    tourDate: string,
    tourHour: string,
    invoiceDetails: InvoiceDetails,
    billingDetails: BillingDetails,
    customerDetails: CustomerDetails,
    bookingState: BookingState
  ) {
    this._bookingId = bookingId;
    this._tourId = tourId;
    this._tourNameTranslated = tourNameTranslated;
    this._tourDate = tourDate;
    this._tourHour = tourHour;
    this._invoiceDetails = invoiceDetails;
    this._billingDetails = billingDetails;
    this._customerDetails = customerDetails;
    this._bookingState = bookingState;
  }

  get bookingId(): number {
    return this._bookingId;
  }

  get tourId(): number {
    return this._tourId;
  }

  get tourNameTranslated(): string {
    return this._tourNameTranslated;
  }

  get tourDate(): string {
    return this._tourDate;
  }

  get tourHour(): string {
    return this._tourHour;
  }

  get invoiceDetails(): InvoiceDetails {
    return this._invoiceDetails;
  }

  get billingDetails(): BillingDetails {
    return this._billingDetails;
  }

  get customerDetails(): CustomerDetails {
    return this._customerDetails;
  }

  get bookingState(): BookingState {
    return this._bookingState;
  }

}
