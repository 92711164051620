import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent }        from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager }           from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';

import { GenericException }             from 'src/app/domain/exceptions/generic.exception';
import { BookingBilling }               from 'src/app/business/models/billing/booking-billing.model';

import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { FindBillingFilterView } from 'src/app/domain/views/billing/find-invoices-filter.view';
import { AdminGuideInteractor } from 'src/app/domain/interactor/admin/guide.interactor';
import { AdminBillingInteractor } from 'src/app/domain/interactor/admin/billing.interactor';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';


@Component({
     selector: 'app-guide-list-billing-admin',
     templateUrl: './list.component.html'
})
export class AdminGuideListBillingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     chunkBookingBilling: ChunkGeneric<BookingBilling>;
     guides: Array<CompanyName>;
     providers: Array<CompanyName>;

     constructor(
          private adminBillingInteractor: AdminBillingInteractor,
          private adminGuideInteractor: AdminGuideInteractor,
          private adminProviderInteractor: AdminProviderInteractor,
          private serverErrorManager: ServerErrorManager) {


     }



     ngOnInit() {

          this.adminProviderInteractor.getProvidersNames()
          .subscribe(
               ( providers: Array<CompanyName> ) => {

                    this.providers = providers;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

     }


     onCompanySelectedTrigger(providerId: number) {
          this.guides = new Array<CompanyName>();
          if ( providerId ) {
               this.adminGuideInteractor.getGuidesNames(providerId)
               .subscribe(
                    ( guides: Array<CompanyName> ) => {

                         this.guides = guides;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )
          }
     }


     onFindBillingTrigger(findBillingFilterView: FindBillingFilterView) {

          if (this.messagePanelComponent) {
            this.messagePanelComponent.showLoading();
          }

          this.adminBillingInteractor.findGuideBilling(findBillingFilterView.dateInit, findBillingFilterView.dateEnd, findBillingFilterView.companyId, findBillingFilterView.guideId, findBillingFilterView.keywords, findBillingFilterView.pageNumber)
          .subscribe(
               (chunkBookingBilling: ChunkGeneric<BookingBilling>) => {

                    this.chunkBookingBilling = chunkBookingBilling;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


}
