// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//ng build --aot --outputHashing=all
export const environment = {
  production: false,
  APP_BASE_URL: 'https://api.bqlever.com/api',
  APP_PAGE_TITLE: 'Booqlever',
  ACCESS_TOKEN_CLIENT_ANGULAR: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjbGllbnRBbmd1bGFyIiwicm9sZSI6IkNMSUVOVF9BTkdVTEFSIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImxhbmd1YWdlQ29kZSI6ImVzIiwiaWF0IjoxNjQ0OTQ3MDkxLCJ1c2VybmFtZSI6ImNsaWVudEFuZ3VsYXIifQ.71PxyNpPQI5eNg74CR6GjJnc1tOOX0ZVESlNpR_jJVkIGw41QuSnex8eKmBrSt6tg79WA7wUvNnXolUagx-ckA',
  STRIPE_API_KEY: 'pk_test_51LgoXTIvnYFiHmy6vZRYVsLlUJkgcptSKu7HvdjfAVQ4d8tKHf6uE791qtt2vRbZWpfczbG3qMlXqJXGPuIfxBa500ydbGaTts',
  WIDGET_URL: 'https://widget.bqlever.com',
  WIDGET_API_KEY: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjbGllbnRSRlQiLCJyb2xlIjoiQ0xJRU5UIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImxhbmd1YWdlQ29kZSI6ImVzIiwiaWF0IjoxNjQ1MDMwMTM2LCJ1c2VybmFtZSI6ImNsaWVudFJGVCJ9.E35I8lL_2oOzJe5je1CGN64GXb7C2mphn5xBQZpJ3i1Ideaoe7ODSvqjWBUL28wydEdEDaAKfT-F8rC6ZykJ6Q',
  PRIMARY_COLOR: '#33CBC7'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
