import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { ImageUtils } from '../../models/image/common/image.utils';
import { TourResumeAdmin } from '../../models/tour/tour-resume-admin.model';

import { TourFormView } from 'src/app/domain/views/provider/tour/tour-form.view';
import { ImageDetails } from '../../models/image/image-details.model';
import { TourProvider } from '../../models/tour/tour-provider.model';
import { AdminTourApiEndpointsUtils } from '../../api/endpoints/admin-tour-api-endponints.utils';
import { CompanyName } from '../../models/master/company/company-name.model';

@Injectable({
  providedIn: 'root'
})
export class AdminTourService extends BaseService {


  findTours(localeI18N: string, search: string, countryId: number, cityId: number, providerId: number, page: number): Observable<ChunkGeneric<TourResumeAdmin>> {

    return super.sendGetRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminFindToursEndpoint(search, countryId, cityId, providerId, page),
      AdminTourApiEndpointsUtils.getAdminFindToursEndpointAcceptVersion()
    )
      .pipe(
        map((response: any) => {

          const toursResumeAdmin = new Array<TourResumeAdmin>();

          response.items.map((tourBasicApi: any) => {

            const tourResumeAdmin = TourUtils.getTourResumeAdminFromApi(tourBasicApi, localeI18N);
            toursResumeAdmin.push(tourResumeAdmin);

          })


          return new ChunkGeneric<TourResumeAdmin>(toursResumeAdmin, new Pagination(response.total, response.currentPage, response.chunkSize));
        })
      )
  }


  activateTour(tourId: number): Observable<boolean> {

    return super.sendPostRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminActivateToursEndpoint(tourId),
      {},
      AdminTourApiEndpointsUtils.getAdminActivateToursEndpointAcceptVersion()
    )
      .pipe(
        map(() => {

          return true;

        })
      )
  }


  deactivateTour(tourId: number): Observable<boolean> {

    return super.sendPostRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminDeactivateToursEndpoint(tourId),
      {},
      AdminTourApiEndpointsUtils.getAdminDeactivateToursEndpointAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }




  updateTourBid(localeI18N: string, tourId: number, bid: number, bidBlocked: boolean): Observable<TourResumeAdmin> {

    return super.sendPutRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminTourUpdateBidEndpoint(tourId),
      AdminTourApiEndpointsUtils.getAdminTourUpdateBidEndpointObject(bid, bidBlocked),
      AdminTourApiEndpointsUtils.getAdminTourUpdateBidEndpointAcceptVersion()
    )
      .pipe(
        map((tourBasicApi: any) => {

          return TourUtils.getTourResumeAdminFromApi(tourBasicApi, localeI18N);

        })
      )
  }


  updateTourCommission(localeI18N: string, tourId: number, commission: number): Observable<TourResumeAdmin> {

    return super.sendPutRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminTourUpdateCommissionEndpoint(tourId),
      AdminTourApiEndpointsUtils.getAdminTourUpdateCommissionEndpointObject(commission),
      AdminTourApiEndpointsUtils.getAdminTourUpdateCommissionEndpointAcceptVersion()
    )
      .pipe(
        map((tourBasicApi: any) => {

          return TourUtils.getTourResumeAdminFromApi(tourBasicApi, localeI18N);

        })
      )
  }



  updateTourAffiliateCommission(localeI18N: string, tourId: number, isDefaultCommission: boolean, commission: number, isPercentage: boolean): Observable<TourResumeAdmin> {

    return super.sendPutRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminTourUpdateAffiliateCommissionEndpoint(tourId),
      AdminTourApiEndpointsUtils.getAdminTourUpdateAffiliateCommissionEndpointObject(isDefaultCommission, commission, isPercentage),
      AdminTourApiEndpointsUtils.getAdminTourUpdateAffiliateCommissionEndpointAcceptVersion()
    )
      .pipe(
        map((tourBasicApi: any) => {

          return TourUtils.getTourResumeAdminFromApi(tourBasicApi, localeI18N);

        })
      )
  }


  getTour(tourId: number, tourI18NDisplayId: number): Observable<TourProvider> {

    return super.sendGetRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminTourDetailsEndpoint(tourId), AdminTourApiEndpointsUtils.getAdminTourDetailsEndpointAcceptVersion())
      .pipe(
        map((tourApi: any) => {

          return new TourProvider(new CompanyName(tourApi.provider.id, tourApi.provider.companyName), TourUtils.getTourFromApi(tourApi, tourI18NDisplayId));

        })
      )
  }


  createTour(tourForm: TourFormView): Observable<number> {

    return super.sendPostRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminCreateTourEndpoint(),
      AdminTourApiEndpointsUtils.getAdminUpdateTourObject(tourForm),
      AdminTourApiEndpointsUtils.getAdminCreateTourEndpointAcceptVersion()
    )
      .pipe(
        map((response: any) => {

          return response.id;

        })
      )
  }


  updateTour(tourForm: TourFormView): Observable<number> {

    return super.sendPutRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminUpdateTourEndpoint(tourForm.id),
      AdminTourApiEndpointsUtils.getAdminUpdateTourObject(tourForm),
      AdminTourApiEndpointsUtils.getAdminUpdateTourEndpointAcceptVersion()
    )
      .pipe(
        map((response: any) => {

          return response.id;

        })
      )
  }


  updateTourI18N(tourForm: TourFormView): Observable<number> {

    return super.sendPutRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminUpdateTourEndpoint(tourForm.id),
      AdminTourApiEndpointsUtils.getAdminUpdateTourI18NObject(tourForm),
      AdminTourApiEndpointsUtils.getAdminUpdateTourEndpointAcceptVersion()
    )
      .pipe(
        map((response: any) => {

          return response.id;

        })
      )
  }


  getTourImages(tourId: number): Observable<Array<ImageDetails>> {

    return super.sendGetRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminTourImagesEndpoint(tourId),
      AdminTourApiEndpointsUtils.getAdminTourImagesEndpointAcceptVersion()
    )
      .pipe(
        map((response: any) => {

          const tourImages = new Array<ImageDetails>();

          response.map((imageApi: any) => {
            tourImages.push(ImageUtils.getImageDetailsFromApi(imageApi));
          });

          return tourImages;

        })
      )
  }


  uploadImageTour(tourId: number, image: File): Observable<ImageDetails> {

    return super.sendPostRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminUploadImageTourEndpoint(tourId),
      image,
      AdminTourApiEndpointsUtils.getAdminUploadImageTourEndpointAcceptVersion()
    )
      .pipe(
        map((imageApi: any) => {
          return ImageUtils.getImageDetailsFromApi(imageApi);
        })
      )
  }


  deleteTourImage(tourId: number, imageId: number): Observable<boolean> {

    return super.sendDeleteRequestWithRefresh(
      AdminTourApiEndpointsUtils.getAdminTourDeleteImageEndpoint(tourId, imageId),
      AdminTourApiEndpointsUtils.getAdminTourDeleteImageEndpointAcceptVersion()
    )
      .pipe(
        map(() => {
          return true;
        })
      )
  }



}
